@import '../../styles/controls';

.rootContainer {
  height: 100%;
  width: 100%;
}

.wordcloudtoolTip {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  background-color: hsl(0deg 0% 0% / 80%);
  border-radius: 10px;
  color: hsl(0deg 0% 100%);
  animation: 0.5s show-tooltip-anim forwards;
}
@keyframes show-tooltip-anim {
  from {
    opacity: 0;
    margin-top: -15px;
  }
  to {
    opacity: 1;
    margin-top: 0px;
  }
}

.alertInfo {
  flex: 1 1 auto;
  height: 30px;
  padding: 0 0.75rem;
  margin: 0 0 0.75rem 0;
  border: $border;
  border: solid 1px theme-colors('functional', 'info');
  // background-color: theme-colors('functional', 'info', 0.2);
  color: theme-colors('primary', 'base');
  @extend %flexbox;
  @extend %all-center;
  @extend %radius-sm;
}