@import "../../styles/controls";
.loaderBlock {
  position: relative;
  @extend %flex-column;
  .loaderElement {
    flex: 1 1 auto;
    &:first-child {
      width: 80% !important;
    }
  }
}
.loaderElement {
  width: 100%;
  position: relative;
  height: 4px;
  overflow: hidden;
  @include animation("loader 1s infinite ease-in-out");
  @include keyframes(loader) {
    0% {
      background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
      background-color: rgba(165, 165, 165, 0.2);
    }
    100% {
      background-color: rgba(165, 165, 165, 0.1);
    }
  }
  &:before {
    content: "";
    position: absolute;
    left: -100px;
    top: 0;
    height: 100%;
    width: 50%;
    @include linear-gradient(
      to right,
      (rgba(165, 165, 165, 0.03) 0%, rgba(165, 165, 165, 0.05) 50%, rgba(165, 165, 165, 0.03) 100%)
    );
    @include animation("overlay 1s cubic-bezier(0.2, 0, 0.2, 1) infinite");
    @include keyframes(overlay) {
      from {
        left: -100px;
      }
      to {
        left: 100%;
      }
    }
  }
}
