@import "../../styles/controls";
.pagination-block {
  @extend %flex-column;
}
.ant-pagination {
  flex: 1 1 auto;
  column-gap: 0.5rem;
  @extend %flexbox;
  @extend %item-center;
  li {
    flex: 0 0 auto;
    margin: 0;
    &.ant-pagination-total-text {
      margin-right: auto;
      font-size: 18px;
      color: theme-colors("grays", "gray-6");
    }
    &.ant-pagination-item {
      height: 30px;
      min-width: 30px;
      a {
        flex: 0 0 auto;
        height: 30px;
        min-width: 30px;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin: 0px;
        color: theme-colors("grays", "gray-6");
        padding: 0px 8px;
        background-color: transparent;
        border: 0;
        @extend %flexbox;
        @extend %all-center;
        @extend %isOut;
        &:hover {
          color: theme-colors("primary", "base");
          @extend %isIn;
        }
      }
    }
    &.ant-pagination-item,
    &.ant-pagination-prev,
    &.ant-pagination-next {
      border: 0;
      flex: 0 0 auto;
      padding: 0px;
      overflow: hidden;
      background-color: transparent;
      border: 0;
      color: theme-colors("grays", "gray-6");
      @extend %flexbox;
      @extend %all-center;
      @include transition(all 0.8s ease-in-out);
      &:hover {
        border: 0;
        @extend %isIn;
        a {
          color: theme-colors("grays", "gray-8");
          font-weight: 400;
          @extend %isIn;
        }
      }
      &.ant-pagination-disabled {
        padding: 0;
        a {
          color: theme-colors("grays", "gray-6");
          font-weight: 400;
          &:hover {
            font-weight: 400;
          }
        }
      }
      &.ant-pagination-item-active {
        border: 0;
        border: 0;
        a {
          color: theme-colors("primary", "base");
          font-weight: 700;
        }
      }
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: auto;
    height: auto;
    padding: 0;
    a {
      width: auto;
      height: auto;
      font-size: 16px;
      font-weight: 700;
      color: theme-colors("grays", "gray-8");
      @extend %flexbox;
      @extend %all-center;
      &:hover {
        font-weight: 700;
      }
    }
    .ant-pagination-item-link {
      border: 0;
      background-color: transparent;
    }
  }

  .ant-pagination-options {
    flex: 0 0 auto;
    order: 1;
    .ant-pagination-options-size-changer {
      margin: 0;
    }
  }
}
