@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/RedHatDisplay-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/RedHatDisplay-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/RedHatDisplay-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/RedHatDisplay-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/RedHatDisplay-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/RedHatDisplay-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Red Hat Display';
  font-style: normal;
  font-weight: 900;
  src: url('./fonts/RedHatDisplay-Black.ttf') format('truetype');
}

