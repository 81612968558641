@import '../../styles/controls';

 .modalLoaderInner {
    flex: 0 0 auto;
    @extend %flex-column;
    @extend %all-center;
    &__loadimage {
      flex: 1 1 auto;
      margin-top: 1.5rem;
      @extend %flex-column;
      @extend %all-center;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 0.25rem;
    }
  }