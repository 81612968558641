@import "../../styles/controls";
.pageHeader {
  flex: 0 1 auto;
  height: 60px;
  position: sticky;
  z-index: 9;
  top: 0;
  padding: 0 $tab-padding-md;
  background-color: theme-colors("grays", "gray-0");
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
  @include box-shadow(0 2px 2px theme-colors("grays", "gray-10", 0.1));
  h3 {
    font-size: 22px;
    font-weight: 600;
    color: theme-colors("grays", "gray-9");;
  }
  &__controlls {
    flex: 0 0 auto;
    column-gap: 1.5rem;
    @extend %flexbox;
    @extend %item-center;
  }
}
