@import "../../styles/controls";
.filterBlock {
  flex: 1 1 auto;
  max-height: 100vh;
  @extend %flex-column;
  &__options {
    flex: 1 1 auto;
    @extend %flex-column;
    .filterContainer {
      @extend %flex-column;
    }
  }
}
