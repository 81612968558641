@import '../../styles/controls';
.ant-switch {
  flex: 0 0 auto;
  height: 30px;
  min-width: 60px;
  max-width: 60px;
  border: $border;
  border-width: 3px;
  background-color: transparent;
  background-image: none;
  border-color: theme-colors('primary', 'base');
  @include box-shadow(0 0 0 0 transparent);
  @extend %radius-full;
  .ant-switch-handle {
    top: 3px;
    left: 4px;
    width: 18px;
    height: 18px;
    &:before {
      background-color: theme-colors('primary', 'base');
      @include box-shadow(0 0 0 0 transparent);
      @extend %radius-round;
      @extend %isIn;
    }
  }
  &.ant-switch-small {
    height: 16px;
    min-width: 30px;
    max-width: 30px;
    border-width: 2px;
    .ant-switch-handle {
      top: 2px;
      left: 2px;
      width: 8px;
      height: 8px;
    }
    &.ant-switch-checked {
      @extend %isIn;
      .ant-switch-handle {
        left: calc(100% - 10px);
      }
    }
  }
  &.ant-switch-checked {
    border-color: theme-colors('primary', 'base');
    .ant-switch-handle {
      left: calc(100% - 22px);
      &:before {
        background-color: theme-colors('primary', 'base');
      }
    }
    .ant-switch-inner {
      margin: 0;
      height: 12px;
    }
  }
  &:focus {
    @include box-shadow(0 0 0 0 transparent);
  }
  &.social-switch {
    border-color: theme-colors('grays', 'gray-3');
    .ant-switch-handle {
      @extend %isIn;
      &:before {
        background-color: theme-colors('grays', 'gray-3');
      }
    }
    &.ant-switch-checked {
      border-color: theme-colors('grays', 'gray-0');
      .ant-switch-handle {
        @extend %isIn;
        &:before {
          background-color: theme-colors('grays', 'gray-0');
        }
      }
    }
  }
}
