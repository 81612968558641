@import 'styles/controls';
.serachResult {
  flex: 1 1 auto;
  row-gap: 1rem;
  padding: 1rem 0;
  @extend %flex-column;
}
.tabRightBar {
  flex: 1 1 auto;
  column-gap: 1rem;
  @extend %flexbox;
}
.selectionSection {
  display: flex;
  justify-content: center;
  background-color: lightblue; 
  align-items: center;
  gap: 12px;
}

.selectionIcons {
  display: flex;
  padding: 2px;
  border-radius: 2px;
}

.selectionButtons {
  display: flex;
  padding: 2px;
  border-radius: 2px;
}