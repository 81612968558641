@import '../../../../../styles/controls';

.otpForms,
.resetBlock {
  position: relative;
  @extend %flex-column;
  @extend %justify-center;
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: theme-colors('grays', 'gray-10');
  }
  p {
    font-size: 14px;
    margin-bottom: 1.5rem;
    color: theme-colors('grays', 'gray-8');
  }
  .otpList,
  .resetPassword {
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
    }
    .passwordErrorBlock {
      color: theme-colors('functional', 'error');
  }
  .otpError {
    @extend %flex-column;
    .invalidOtp {
      text-align: left;
      margin: 0;
      color: theme-colors('functional', 'error');
    }
    .resendOtpLink {
      margin-top: 5%;
      color: theme-colors('secondary', 'dark');
      text-align: left;
      width: 90px;
      &:hover {
        color: theme-colors('grays', 'gray-8');
        cursor: pointer;
      }
    }
  }
}
