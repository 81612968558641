@import '../../../styles/controls';
.userTagElement {
  min-height: 32px;
  color: theme-colors('grays', 'gray-8');
  flex: 1 1 auto;
  padding: 0 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: solid 1px theme-colors('primary', 'base');
  column-gap: 0.5rem;
  @extend %flexbox;
  @extend %all-center;
  @extend %radius-md;
  @extend %isIn;
  span {
    flex: 0 0 auto;
    &:first-child {
      min-width: 16px;
      min-height: 16px;
      max-width: 16px;
      max-height: 16px;
      svg {
        width: 100%;
        height: 100%;
        @extend %isOut;
        path {
          fill: theme-colors('primary', 'dark');
          @extend %isOut;
        }
      }
    }
  }
}


.selectedTag {
  color: theme-colors('primary', 'dark');
  background-color: theme-colors('primary', 'light');
  font-weight: bold;
}

.normalTag {
  color: theme-colors('grays', 'gray-8');
  background-color: transparent;
  font-weight: normal;
}

.articlePreview {
  @extend %flex-column;
  border: 1px solid theme-colors('grays', 'gray-9');
  h3 {
    font-size: 18px;
    margin-bottom: 1rem;
    color: theme-colors('primary', 'dark');
  }
  &__pdfblock {
    flex: 1 1 auto;
    @extend %flex-column;
    .pdfWrapper {
      @extend %flex-column;
      .pdfCanvas {
        flex: 1 1 auto;
        margin: 0;
        width: 100% !important;
      }
      .noPdf {
        color: theme-colors('functional', 'warning');
        width: 100%;
        padding: 2rem 0;
        text-align: center;
      }
    }
    .pdfPagination {
      padding: 0.5rem 0;
      @extend %flexbox;
      @extend %all-center;
      li {
        flex: 0 0 auto;
        font-size: 16px;
        padding: 0 0.25rem;
        color: theme-colors('grays', 'gray-9');
        .pdfPrevious,
        .pdfNext {
          border: 0;
          background-color: transparent;
          cursor: pointer;
          color: theme-colors('grays', 'gray-9');
          &:hover {
            color: theme-colors('primary', 'dark');
            font-weight: 600;
          }
          &[disabled] {
            color: theme-colors('grays', 'gray-5');
            &:hover {
              color: theme-colors('grays', 'gray-5');
              font-weight: 400;
            }
          }
        }
      }
    }
  }
  &__taxonomies {
    @extend %flex-column;
    .taxonomieScroll {
      @extend %flex-column;
      .taxonomieList {
        padding-right: 1rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %item-wrap;
        li {
          padding: 0.25rem;
          @extend %flex-column;
          span {
            border: $line;
            padding: 0.5rem;
            background-color: theme-colors('primary', 'base', 0.1);
            @extend %radius-md;
          }
        }
      }
    }
  }
}

.docViewWrapper {
  :global {
    #proxy-renderer{
      overflow-y: auto !important;
      #pdf-renderer{
        #pdf-controls{
          background-color: white;
          border-bottom: 1px solid theme-colors('grays', 'gray-9');
        }
      }
    }
    .react-pdf__Document {
      width: 100%;
      overflow: scroll
    }
  }
}

.noRenderer{
  @extend %flexbox;
  @extend %all-center;
  width: 100%;
}
