@import '../../styles/controls';
.customeCheckcontainer {
  row-gap: 1rem;
  @extend %flex-column;
  .checkboxListbox {
    // row-gap: 1rem;
    padding-right: 0.5rem;
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      @extend %flex-column;
      .loadmoreButtons {
        flex: 1 1 auto;
        @extend %flexbox;
        .loadBtn {
          padding: 0.25rem 0.5rem;
          border: 1px solid theme-colors('grays', 'gray-3');
          @extend %isIn;
          @extend %radius-full;
          &:hover {
            border-color: theme-colors('secondary', 'base');
            @extend %isIn;
          }
        }
      }
      .checkboxListpane {
        flex: 1 1 auto;
        min-width: 100%;
        column-gap: 0.25rem;
        @extend %flexbox;
        @extend %item-center;
        .checkboxItem {
          flex: 0 0 auto;
          min-width: 22px;
          max-width: 22px;
          min-height: 22px;
          max-height: 220px;
          @extend %flexbox;
          @extend %all-center;
        }
        p {
          flex: 1 1 auto;
          max-width: 160px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .countPane {
          flex: 0 0 auto;
          font-weight: 600;
          @extend %flexbox;
          @extend %item-center;
        }
      }
    }
  }
}
