@import "../../../../styles/controls";
.dashboardBlock {
  flex: 1 1 auto;
  row-gap: 1.5rem;
  max-width: 100%;
  overflow: hidden;
  @extend %flex-column;
  &__header {
    flex: 0 1 auto;
    padding: 0 1.5rem;
    @extend %flexbox;
    @extend %item-center;
    li {
      flex: 0 0 auto;
      @extend %flex-column;
      .dashbordChartinput {
        border: 0;
        font-size: 20px;
        width: 100% !important;
        font-style: italic;
        color: theme-colors("primary", "dark");
        border-bottom: dashed 1px theme-colors("primary", "dark");
        background-color: transparent;
      }
    }
  }
  &__content {
    flex: 1 1 auto;
    padding: 0 1.5rem;
    row-gap: 1.5rem;
    @extend %flex-column;
  }
  &__footer {
    flex: 0 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
    li {
      flex: 0 0 auto;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
