.container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    p{
        margin-top: -2.5rem;
    }
    li{
        &:last-child{
            display: flex;
            gap: 1rem;
            justify-content: flex-end;
            margin-top: 2rem;
        }
    }
}