@import '../../styles/controls';
.downloadElementblock {
  padding: 1.5rem;
  row-gap: 1rem;
  @extend %flex-column;
  &__heading {
    flex: 1 0 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    margin-left: auto;
    column-gap: 5%;
  }
  &__body {
    @extend %flex-column;
  }
}
