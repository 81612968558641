@import '../../styles/controls';
.ant-input-affix-wrapper {
  position: relative;
  background-color: transparent;
  z-index: 0;
  padding: 0 2rem 0 0.625rem;
  border: $border;
  min-height: $height-md;
  @extend %flexbox;
  @extend %item-center;
  @extend %radius-md;
  .ant-input-suffix {
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    right: 0.5rem;
    @extend %flexbox;
    @extend %all-center;
    @include transform(translateY(-50%));
  }
  &:focus,
  &:hover,
  .ant-input-affix-wrapper-focused {
    border-color: theme-colors('primary', 'base');
    @include box-shadow(0 0 0 transparent);
    .ant-input {
      background-color: theme-colors('grays', 'gray-0');
      color: theme-colors('grays', 'gray-5');
    }
  }
  .ant-input {
    flex: 0 0 auto;
    position: relative;
    background-color: transparent;
    border: 0;
    z-index: -1;
    height: auto !important;
    color: theme-colors('grays', 'gray-5');
    @include border-radius(0);
    @extend %radius-md;
    &:focus {
      opacity: 1;
      background-color: theme-colors('grays', 'gray-0') !important;
      @include box-shadow(0 0 0 transparent !important);
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px white inset !important;
      // color: theme-colors('grays', 'gray-5') !important;
      // font-size: 1rem !important;
    }
  }
  &.ant-input-affix-wrapper-lg {
    height: $height-lg;
  }
  &.ant-input-affix-wrapper-sm {
    height: $height-sm;
    @extend %radius-xs;
  }
}
.ant-input-affix-wrapper {
  margin: 0;
  &:not(.ant-input-affix-wrapper-disabled) {
    background-color: transparent;
    &:hover {
      border: theme-colors('secondary', 'base') solid 1px;
    }
  }
}
