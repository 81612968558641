@import '../../styles/controls';

.mainSearchInsights {
  flex: 1 1 auto;
  @extend %flexbox;
  @extend %all-center;
  @extend %isIn;
  &__input {
    flex: 1 1 auto;
    height: 33px;
    position: relative;
    @extend %radius-sm;
    @extend %isIn;
    min-width: 450px;
    .inputElement {
      flex: 1 1 auto;
      position: relative;
      height: 33px;
      z-index: 1;
      border: 0;
      border: theme-colors('primary', 'dark') solid 1px;
      background-color: theme-colors('grays', 'gray-0');
      padding-left: 2rem;
      min-height: 33px;
      @extend %isIn;
    }
    .serachIconInsights {
      position: absolute;
      min-width: 20px;
      min-height: 20px;
      max-width: 20px;
      max-height: 20px;
      width: 20px;
      height: 20px;
      z-index: 3;
      overflow: hidden;
      left: 0.5rem;
      top: 50%;
      @include transform(translateY(-50%));
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('grays', 'gray-6');
        }
      }
    }
  }
  .insightSearchInfo{
    margin-left: 10px;
    margin-top: 7px;
    svg {
      height: 20px;
    }
  }
}
