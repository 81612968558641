@import 'styles/controls';
.articleHeader {
  flex: 0 1 auto;
  row-gap: 1rem;
  padding: 0 1.5rem;
  @extend %flex-column;
  .breadcrumbBlock {
    flex: 0 1 auto;
    column-gap: 1rem;
    column-gap: 1rem;
    @extend %flexbox;
    @extend %item-nowrap;
    &__label {
      flex: 0 0 auto;
      p {
        color: theme-colors('primary', 'dark');
      }
    }
    .articleWordlabels {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %justify-between;
      &__element {
        flex: 1 1 auto;
        @extend %flex-column;
        .articleWordlist {
          flex: 1 1 auto;
          ol {
            flex: 1 1 auto;
            column-gap: 0.25rem;
            row-gap: 0.25rem;
            @extend %flexbox;
            @extend %item-wrap;
            li {
              text-transform: capitalize;
            }
          }
        }
      }
      &__backitem {
        flex: 0 0 auto;
        .clearButton {
          color: theme-colors('primary', 'dark');
          padding: 0 0.5rem;
          height: 24px;
          border: solid 1px theme-colors('primary', 'base');
          @extend %radius-sm;
          &:hover {
            color: theme-colors('primary', 'base');
          }
        }
      }
    }
  }
  .resultPagination {
    row-gap: 1px;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    @extend %item-wrap;
  }
}

.resultContainer {
  flex: 1 1 auto;
  @extend %flex-column;
  .resultInner {
    flex: 1 1 auto;
    padding: 0 1.5rem;
    @extend %flex-column;
  }
}
.advancesearchQuery {
  column-gap: 1rem;
  position: relative;
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
  &__item {
    flex: 1 1 auto;
  }
  &__button {
    position: absolute;
    right: 20px;
    top: 10px;
    min-width: 26px;
    min-height: 26px;
    height: 26px;
    border: solid 1px theme-colors('functional', 'info');
    @extend %flexbox;
    @extend %all-center;
    @extend %radius-round;
    span {
      width: 14px;
      height: 14px;
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('functional', 'info');
        }
      }
    }

    &:hover {
      background-color: theme-colors('functional', 'info');
      path {
        fill: theme-colors('grays', 'gray-0');
      }
    }
  }
}
