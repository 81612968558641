@import 'styles/base/reset';
@import 'styles/base/typography';
@import 'styles/base/font-face';
@import 'styles/controls';
* {
  margin: 0;
  padding: 0;
}
::selection {
  color: theme-colors('grays', 'gray-0');
  background-color: theme-colors('primary', 'base');
}
body,
html {
  height: 100%;
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: $font-web;
  color: theme-colors('grays', 'gray-8');
  background-color: theme-colors('grays', 'gray-1');
  @include generatorText(0.875, $regular);
  &.disable-scroll {
    overflow: hidden;
  }
  .Toastify__toast-container--top-right {
    top: 5em !important;
  }
  &.scrollDisable {
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      position: fixed;
      z-index: 999;
      top: 0;
      left: 0;
      max-width: 100%;
      min-width: 100%;
      height: 100vh;
      background-color: theme-colors('grays', 'gray-10', 0.5);
      @extend %isIn;
    }
  }
}


.landing-background{
  background-image: url('../public/img/banner-background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: 100% 100%;
  

  .pingar-logo{
    background-image: url('../public/img/Pingar_Logo_Reverse_ColourIcon_RGBDigital.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    width: 400px;
    height: 200px;

  }

  .pingar-logo-small{
    width: 80px;
    height: 60px;
  }

  svg path {   
    fill: #ffeb3b;
  }

  p{
    color: white !important;
  }

  h3{
    color: white
  }

  h1{
    margin-bottom: 280px;
    margin-top: -260px;

    margin-left: 0px;

    font-weight: 800;
    font-size: 48px;
    z-index: 100;
    
  }

  .bottom-right-quarter{
    margin-top: 240px;
    margin-left: 240px;
  }

  .warning-box{
    border: 2px solid #ffeb3b;
    padding: 8px;
    display: flex; 
    border-radius: 4px;
    width: 420px;
    margin-top: 24px;

    font-size: 12px;
  }
  .app-version{
    p{
      font-size: 14px;
      column-gap: 0.5rem;

      display: flex;
      flex-direction: row;

      span {
        color: rgba(255, 255, 255, 0.5);
        &:last-child {
          color: rgba(255, 255, 255, 1);
          font-weight: 500;
        }
      }
    }
  }
}