@import '../../../../styles/controls';
.leftBlock {
  flex: 1 1 auto;
  padding: 30px;
  @extend %flex-column;
  @extend %justify-between;
  @include media('>=992px') {
    @include justify-content(center);
    padding-right: 87px;
  }
  &__theam {
    flex: 0 1 auto;
    @extend %flex-column;
    span {
      flex: 1 1 auto;
      @extend %flex-column;
      @extend %all-center;
    }
  }
}
