@import '../../../../styles/controls';
.loginForms {
  flex: 1 1 auto;
  position: relative;
  padding: 30px;
  @extend %flex-column;
  @extend %justify-center;
  @include media('>=992px') {
    padding: 0 0 0 87px;
  }
  &__inner {
    flex: 0 0 auto;
    position: relative;
    z-index: 1;
    row-gap: 2rem;
    @extend %flex-column;
    .brandLogo {
      flex: 0 1 auto;
      @extend %flex-column;
      @extend %all-center;
      @include media('>=992px') {
        @include justify-content(flex-start);
        @include align-items(flex-start);
      }
      span {
        flex: 0 0 auto;
        svg {
          width: 100%;
          path {
            fill: theme-colors('grays', 'gray-0');
          }
        }
      }
    }
    .compantSlogen {
      position: relative;
      @extend %flex-column;
      @extend %justify-center;
      h3 {
        color: theme-colors('grays', 'gray-0');
        font-size: 32px;
        line-height: 34px;
      }
      p {
        color: theme-colors('grays', 'gray-0');
        font-size: 16px;
      }
    }
    .listWrapper {
      flex: 0 0 auto;
      z-index: 1;
      position: relative;
      @extend %flex-column;
      @extend %justify-center;
      .loginList {
        position: relative;
        z-index: 1;
        flex: 0 1 auto;
        @extend %flex-column;
        @include media('>=992px') {
          max-width: 400px;
        }
        li {
          flex: 1 1 auto;
          @extend %flexbox;
          @extend %item-center;
          &:not(:last-child) {
            margin-bottom: 1rem;
          }
          .forgetPassword {
            @extend %flexbox;
            @extend %item-center;
            @extend %justify-between;
            &__item {
              flex: 0 0 auto;
              .loginLink {
                text-decoration: underline;
                color: theme-colors('primary', 'dark');
                @extend %isOut;
                &:hover {
                  color: theme-colors('primary', 'base');
                  @extend %isIn;
                }
              }
            }
          }
        }
      }
    }
    .alertWrapper {
      flex: 0 1 auto;
      position: relative;
      @extend %flexbox;
      @extend %item-center;
      z-index: 1;
      .loginAlert {
        z-index: 1;
        max-height: 100px;
        padding: 0.625rem 1.5rem;
        border: theme-colors('functional', 'warning') 1px solid;
        @extend %flexbox;
        @extend %item-center;
        @extend %radius-md;
        .alertIcon {
          flex: 0 0 auto;
          width: 60px;
          height: 60px;
          @extend %flexbox;
          @extend %all-center;
          span {
            flex: 1 1 auto;
            @extend %flexbox;
            @extend %all-center;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: theme-colors('functional', 'warning');
              }
            }
          }
        }
        .alertContent {
          padding-left: 34px;
          flex: 1 1 auto;
          h3 {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            color: theme-colors('grays', 'gray-0');
          }
          p {
            font-size: 14px;
            line-height: 18px;
            color: theme-colors('grays', 'gray-0');
          }
        }
      }
    }
    .superAdminlogin {
      cursor: pointer;
      @extend %flexbox;
      a {
        font-size: 16px;
        font-weight: 600;
        color: theme-colors('functional', 'warning');
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .appVersion {
      @extend %flex-column;
      p {
        font-size: 14px;
        column-gap: 0.5rem;
        @extend %flexbox;
        @extend %item-center;
        span {
          color: theme-colors('grays', 'gray-0', 0.5);
          &:last-child {
            color: theme-colors('grays', 'gray-0');
            font-weight: 500;
          }
        }
      }
    }
  }
}
.forgetBlock {
  min-height: 200px;
  @extend %flex-column;
  .passwordEmail {
    flex: 1 1 auto;
    h3 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 10px;
      color: theme-colors('grays', 'gray-10');
    }
    p {
      font-size: 14px;
      margin-bottom: 1.5rem;
      color: theme-colors('grays', 'gray-8');
    }
  }
}
.userloginFields {
  row-gap: 1rem;
  @extend %flex-column;
  li {
    flex: 0 1 auto;
  }
}
