@import "../constants/mixins";
%inline-flex {
  @include inline-flex;
}
%flexbox {
  @include flexbox();
  @include flex-direction(row);
}
%flex-column {
  @include flexbox();
  @include flex-direction(column);
}
%row-reverse {
  @include flex-direction(row-reverse);
}
%column-reverse {
  @include flex-direction(column-reverse);
}
%all-center {
  @include justify-content(center);
  @include align-items(center);
}

%justify-center {
  @include justify-content(center);
}

%justify-between {
  @include justify-content(space-between);
}

%justify-around {
  @include justify-content(space-around);
}

%justify-start {
  @include justify-content(flex-start);
}

%justify-end {
  @include justify-content(flex-end);
}

%item-center {
  @include align-items(center);
}

%item-start {
  @include align-items(flex-start);
}

%item-end {
  @include align-items(flex-end);
}

%item-stretch {
  @include align-items(stretch);
}

%item-nowrap {
  @include flex-wrap(nowrap);
}

%item-wrap {
  @include flex-wrap(wrap);
}

%break-text {
  overflow-wrap: break-word;
  word-break: break-all;
  hyphens: manual;
  white-space: normal;
}
%radius-none {
  @include border-radius(0);
}
%radius-left {
  @include border-radius(0.25rem 0 0 0.25rem);
}
%radius-right {
  @include border-radius(0 0.25rem 0.25rem 0);
}
%radius-xs {
  @include border-radius(0.125rem);
}
%radius-sm {
  @include border-radius(0.188rem);
}
%radius-md {
  @include border-radius(0.25rem);
}
%radius-lg {
  @include border-radius(0.375rem);
}
%radius-xl {
  @include border-radius(0.5rem);
}
%radius-xxl {
  @include border-radius(0.75rem);
}
%radius-full {
  @include border-radius(50px);
}
%radius-round {
  @include border-radius(50%);
}
%login-theam {
  @include linear-gradient(to right bottom, #2c04a5, #1ba4da);
}
%shadow-border {
  @include box-shadow(
    2px 6px 8px 3px theme-colors("grays", "gray-10", 0.03),
    0px 0px 1px 1px theme-colors("grays", "gray-10", 0.03)
  );
}
%normal-shadow {
  @include box-shadow(0 2px 4px theme-colors("grays", "gray-10", 0.1));
}

%isIn {
  @include transition(all 0.4s ease-out);
}
%isOut {
  @include transition(all 0.6s ease-in);
}
%max-height-in {
  @include transition(max-height 0.2s ease-out);
}
%max-height-out {
  @include transition(max-height 0.4s ease-in);
}

%max-width-in {
  @include transition(max-width 0.4s ease-out);
}
%max-width-out {
  @include transition(max-width 0.6s ease-in);
}
%height-in {
  @include transition(height 0.4s ease-out);
}
%height-out {
  @include transition(height 0.6s ease-in);
}
