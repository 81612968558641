@import 'styles/controls';
.tagBlock {
  @extend %flex-column;
  h3 {
    font-size: 24px;
    margin-bottom: 1rem;
  }
  .tagsAlert {
    flex: 1 1 auto;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    border: solid 1px theme-colors('functional', 'info');
    background-color: theme-colors('functional', 'info', 0.2);
    @extend %flexbox;
    @extend %item-center;
    span {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      width: 18px;
      height: 18px;
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('primary', 'base');
        }
      }
    }
    p {
      font-size: 13px;
      color: theme-colors('primary', 'base');
    }
  }
  &__list {
    @extend %flexbox;
    @extend %item-center;
    @extend %item-wrap;
    li {
      flex: 0 0 auto;
      margin: 0.25rem;
      @extend %flex-column;
      .inputsAddtags {
        border: $border;
        max-width: 150px;
        padding-left: 6px;
        height: 30px;
        width: 150px;
        @extend %radius-sm;
        &:focus,
        &:focus-visible {
          outline: 0;
        }
      }
      .tagElement {
        height: 30px;
        padding: 0 0.75rem;
        background-color: theme-colors('primary', 'base');
        @extend %flexbox;
        @extend %all-center;
        @extend %radius-sm;
        .tagText {
          flex: 0 0 auto;
          padding-right: 0.5rem;
          color: theme-colors('grays', 'gray-0');
        }
        .closeIcon {
          width: 8px;
          height: 8px;
          flex: 0 0 auto;
          @extend %flexbox;
          @extend %all-center;
          svg {
            width: 100%;
            height: 100%;
            path {
              fill: theme-colors('grays', 'gray-0');
            }
          }
        }
      }
      .notabPane {
        flex: 1 1 auto;
        height: 30px;
        padding: 0 0.75rem;
        border: $border;
        border: solid 1px theme-colors('functional', 'info');
        background-color: theme-colors('functional', 'info', 0.2);
        color: theme-colors('primary', 'base');
        @extend %flexbox;
        @extend %all-center;
        @extend %radius-sm;
      }
    }
    .inputloadPane {
      flex: 0 0 auto;
      margin: 0.25rem;
      @extend %flexbox;
    }
  }
}
