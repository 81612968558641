@import '../../../styles/controls';
.selectFilter {
  @extend %flex-column;
  &__search {
    flex: 0 1 auto;
    padding-bottom: 1rem;
    @extend %flex-column;
  }
  &__list {
    flex: 1 1 auto;
    @extend %flex-column;
  }
}
