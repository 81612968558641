@import "../../styles/controls";
.errorState {
  flex: 1 1 auto;
  min-height: 300px;
  @extend %flex-column;
  @extend %all-center;
  &__content {
    flex: 0 0 auto;
    @extend %flex-column;
    @extend %all-center;
    .errorImage {
      flex: 1 1 auto;
      max-width: 200px;
      margin-bottom: 20px;
      @extend %flex-column;
      @extend %all-center;
      span {
        flex: 1 1 auto;
      }
    }
    h3 {
      flex: 0 1 auto;
      margin-bottom: 0.5rem;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: theme-colors("grays", "gray-8");
    }
    .stateContent {
      flex: 1 1 auto;
      p {
        font-size: 16px;
        text-align: center;
        color: theme-colors('grays', 'gray-6',);
      }
    }
  }
  &__button {
    margin-top: 2rem;
    @extend %flexbox;
    @extend %all-center;
  }
}
