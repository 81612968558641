@import 'styles/controls';
.insightElements {
  flex: 0 1 auto;
  row-gap: 1rem;
  @extend %flex-column;
  &__item {
    flex: 0 1 auto;
    background-color: theme-colors('grays', 'gray-0');
    @extend %flex-column;
    .insightFilter,
    .listBlock {
      flex: 1 1 auto;
      row-gap: 0.5rem;
      @extend %flex-column;
      h4 {
        font-size: 16px;
        font-weight: 600;
      }
      &__content {
        flex: 1 1 auto;
        @extend %flex-column;
        .availableInputs {
          flex: 0 1 auto;
          margin-bottom: 1rem;
        }
        .listInputs {
          flex: 0 1 auto;
        }
        .chartParameters {
          flex: 1 1 auto;
          row-gap: 0.5rem;
          position: relative;
          z-index: 0;
          @extend %flex-column;
          li {
            flex: 1 1 auto;
            position: relative;
            z-index: 0;
            input {
              background-color: transparent;
            }
          }
        }
        .availableList {
          flex: 1 1 auto;
          @extend %flex-column;
          li {
            flex: 1 1 auto;
            position: relative;
            z-index: 999;
            padding: 5px 10px;
            color: theme-colors('grays', 'gray-8');
            background-color: theme-colors('grays', 'gray-0');
            @extend %radius-sm;
            &:not(:last-child) {
              margin-bottom: 0.5rem;
            }
            &:hover {
              color: theme-colors('grays', 'gray-9');
            }
          }
        }
      }
    }
  }
  &__footer {
    flex: 0 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
  }
}

.chartSavebutton {
  margin-left: 1rem;
  @extend %flexbox;
  @extend %item-center;
  &__element {
    flex: 0 0 auto;
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}

.saveinsightBlock {
  @extend %flex-column;
  &__input {
    flex: 1 1 auto;
    margin-bottom: 0.5rem;
    @extend %flex-column;
  }
  &__information {
    flex: 0 1 auto;
    font-size: 13px;
    line-height: 16px;
    color: theme-colors('functional', 'warning');
  }
}

.breadCrumbWrapper {
  flex: 0 1 auto;
  @extend %flexbox;
  @extend %justify-between;
  &__elements {
    flex: 1 1 auto;
    @extend %flex-column;
    .cloudLabel {
      flex: 1 1 auto;
      ol {
        flex: 1 1 auto;
        column-gap: 0.25rem;
        row-gap: 0.25rem;
        @extend %flexbox;
        @extend %item-wrap;
        li {
          text-transform: capitalize;
        }
      }
    }
  }
  &__item {
    flex: 0 0 auto;
    @extend %flex-column;
    .clearButton {
      color: theme-colors('primary', 'dark');
      padding: 0 0.5rem;
      height: 24px;
      border: solid 1px theme-colors('primary', 'base');
      @extend %radius-sm;
      &:hover {
        color: theme-colors('primary', 'base');
      }
    }
  }
}

.errorInfo {
  padding: 1%;
  color: theme-colors('functional', 'error');
}
