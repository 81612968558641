@import '../../styles/controls';
.card {
  flex: 1 1 auto;
  height: 100%;
  border: $line;
  background-color: theme-colors('grays', 'gray-0');
  @extend %flex-column;
  @extend %radius-lg;
  @extend %isOut;
  @include box-shadow(
    0 2px 4px 2px theme-colors('grays', 'gray-10', 3%),
    0 1px 1px 0 theme-colors('grays', 'gray-10', 1%)
  );
  &:hover {
    @extend %isIn;
    @include box-shadow(0 0 8px 4px theme-colors('grays', 'gray-10', 0.6%));
  }
  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
  .cardHeader {
    flex: 0 1 auto;
    padding: 1rem;
    border-bottom: solid 1px theme-colors('primary', 'light', 0.1);
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    h2 {
      margin-bottom: 0;
      font-size: 1.5rem;
      color: theme-colors('primary', 'dark');
    }
    h3 {
      margin-bottom: 0;
      font-size: 1.256rem;
      color: theme-colors('primary', 'dark');
    }
  }
  .cardCover {
    flex: 1 1 auto;

    img {
      cursor: pointer;
    }
  }
  .cardBody {
    flex: 1 1 auto;
    padding: 1rem;

    h3 {
      cursor: pointer;
    }
  }
  .cardFooter {
    border-top: solid 1px theme-colors('primary', 'light', 0.1);
    flex: 0 1 auto;
    padding: 1rem;
    @extend %flex-column;
    @extend %all-center;
    @include media('>992px') {
      @include flex-direction(row);
      @include justify-content(flex-end);
      @include align-items(center);
      button {
        flex: 0 0 auto;
        min-width: 80px;
        &:not(:last-child) {
          margin-bottom: 0 !important;
          margin-right: 1rem !important;
        }
      }
    }
    button {
      flex: 0 0 auto;
      min-width: 80px;
      &:not(:last-child) {
        margin-bottom: 1rem;
        margin-right: 0;
      }
    }
  }
}
