@import "\styles/controls";
.savedInsight {
  row-gap: 1.5rem;
  @extend %flex-column;
  .insightPagination {
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
  }
  .insightData {
    @extend %flex-column;
    &__table {
      flex: 1 1 auto;
    }
    &__pagination {
      flex: 0 1 auto;
      margin-top: 1rem;
    }
  }
}
