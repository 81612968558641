@import "../../styles/controls";
.confirmationMessage {
  flex: 1 1 auto;
  @extend %flex-column;
  h3 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: theme-colors("grays", "gray-10");
  }
  p {
    font-size: 14px;
    font-weight: 400;
    color: theme-colors("grays", "gray-10");
  }
  .messageDeletion {
    padding-top: 1rem;
    @extend %flexbox;
    @extend %item-center;
    span {
      flex: 0 0 auto;
      &:first-child {
        margin-right: 0.5rem;
        color: theme-colors("grays", "gray-6");
      }
      &:last-child {
        color: theme-colors("functional", "error");
      }
    }
  }
  &__list {
    margin-top: 2rem;
    flex: 1 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
    li {
      flex: 0 0 auto;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
}
