@import "../../styles/controls";
.InsightContainer {
  flex: 1 1 auto;
  @extend %flex-column;
  .insightButtonBlock {
    @extend %flexbox;
    @extend %item-center;
    li {
      margin-left: 0.5rem;
    }
  }
}
