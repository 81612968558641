.superAdminLoginWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    .superAdminLoginForm{
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        width: 30rem;
        row-gap: 1rem;
        border-radius: 5px;
        padding: 2%;
        h3{
            margin-left: 0;
        }
        ul{
            display: flex;
            flex-direction: column;
            row-gap: 1rem;
        li {
            width: 100%;
            &:nth-child(3){
                display: flex;
                gap: 1rem;
                justify-content: flex-end;
            }
          }
        }
    }
   
}