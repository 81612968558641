@import "../../../styles/controls";
.downloadPreview {
    flex: 1 1 auto;
    min-height: 800px;
    row-gap: 1rem;
    position: relative;
    @extend %flex-column;
    @extend %justify-center;

    &__search {
        @extend %flexbox;
        @extend %item-center;
        @media print {
            @include justify-content(center !important);
            @include align-items(center !important);
        }
        .downloadInput {
            border: 0;
            font-size: 22px;
            @media print {
                font-size: 36px !important;
            }
        }
    }
    &__chart {
        flex: 1 1 auto;
        @extend %flex-column;
    }
    &__insight {
        min-height: 800px;
        @extend %flexbox;
        @extend %justify-center;
        @media print {
            height: 100% !important;
        }
    }
    &__resources {
        margin-top: 1rem;
        padding: 1.438rem;
        background-color: theme-colors("grays", "gray-0");
        @extend %flex-column;
        h4 {
            font-size: 16px;
            font-weight: 600;
            margin-left: 0.25rem;
            margin-bottom: 0.5rem;
        }
        .resourcesList {
            @extend %flexbox;
            @extend %item-center;
            @extend %item-wrap;
            li {
                flex: 0 0 auto;
                padding: 0.5rem 1rem;
                margin: 0.25rem;
                color: theme-colors("grays", "gray-0");
                background-color: theme-colors("primary", "base", 0.8);
                @extend %radius-lg;
            }
        }
    }
}
