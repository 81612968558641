@import '../../styles/controls';
.ant-btn {
  outline: 0;
  min-width: 80px;
  cursor: pointer;
  color: theme-colors('primary', 'dark');
  border-color: theme-colors('primary', 'dark');
  font-weight: 500;
  flex: 0 0 auto;
  padding: 0.5rem 1rem;
  font-size: 14px;
  column-gap: 0.5rem;
  height: $height-md;
  @extend %flexbox;
  @extend %all-center;
  @extend %isOut;
  @extend %radius-md;
  span {
    flex: 0 0 auto;
    &:empty {
      display: none;
    }
  }
  .button-loader-item {
    width: 22px;
    height: 22px;
  }
  .button-loader-item {
    @extend %flexbox;
    @extend %all-center;
    .loader {
      flex: 0 0 auto;
      .loader__item {
        padding: 0;
        &:before {
          background-color: theme-colors('primary', 'dark');
        }
      }
    }
  }
  .button-icon-pane {
    width: 22px;
    height: 22px;
    overflow: hidden;
    @extend %flexbox;
    @extend %all-center;
    svg {
      width: 80%;
      height: 80%;
      path {
        fill: theme-colors('primary', 'dark');
        @extend %isOut;
      }
    }
  }
  .button-text-block {
    flex: 0 0 auto;
  }
  &:focus,
  &:hover {
    outline: 0;
    border-color: theme-colors('secondary', 'base');
    color: theme-colors('grays', 'gray-8');
    @include box-shadow(0 0 0 transparent);
    @extend %isIn;
    span {
      @extend %isIn;
      svg {
        @extend %isIn;
        path {
          fill: theme-colors('secondary', 'base');
          @extend %isIn;
        }
      }
    }
  }
  &[disabled] {
    background-color: theme-colors('grays', 'gray-3') !important;
    color: theme-colors('grays', 'gray-6') !important;
    &:hover {
      background-color: theme-colors('grays', 'gray-3') !important;
      color: theme-colors('grays', 'gray-6') !important;
    }
  }

  &.ant-btn-primary {
    background-color: theme-colors('primary', 'dark', 0.9);
    color: theme-colors('grays', 'gray-0');
    &:hover {
      background-color: theme-colors('secondary', 'base');
      color: theme-colors('grays', 'gray-8');
    }
    span {
      flex: 0 0 auto;
      svg {
        @extend %isIn;
        path {
          fill: theme-colors('grays', 'gray-0');
        }
      }
    }
    .button-loader-item {
      @extend %flexbox;
      @extend %all-center;
      .loader {
        flex: 0 0 auto;
        .loader__item {
          padding: 0;
          &:before {
            background-color: theme-colors('grays', 'gray-0');
          }
        }
      }
    }
  }
  &.ant-btn-dangerous {
    background-color: theme-colors('functional', 'error');
    &:hover {
      background-color: theme-colors('functional', 'error');
      color: theme-colors('grays', 'gray-0');
    }
  }
  &.ant-btn-dashed {
    border-style: dashed;
  }
  &.ant-btn-link {
    border: 0;
    color: theme-colors('grays', 'gray-8');
  }
  &.ant-btn-line {
    background-color: transparent;
    border-color: theme-colors('primary', 'base');
  }
  &.ant-btn-lg {
    height: $height-lg;
    font-size: 15px;
    padding: 0.75rem 1.5rem;
    @extend %radius-lg;
  }
  &.ant-btn-sm {
    height: $height-sm;
    font-size: 13px;
    min-width: 60px;
    padding: 0.25rem 0.5rem;
    @extend %radius-sm;
  }
  &.ant-btn-circle {
    padding: 0;
    @extend %radius-full;
  }
  /*=== - coustom - =====*/
  &.microsoft-btn {
    border: 0;
    color: theme-colors('grays', 'gray-9');
    background-color: theme-colors('secondary', 'base');
    font-weight: 600;
    max-width: 60%;
    height: 42px;
    @extend %item-center;
    @extend %justify-start;
    span {
      @extend %isIn;
      svg {
        @extend %isIn;
        path {
          fill: theme-colors('grays', 'gray-9');
        }
      }
    }
    &:hover {
      background-color: theme-colors('secondary', 'base');
      color: theme-colors('primary', 'dark');
      span {
        @extend %isIn;
        svg {
          @extend %isIn;
          path {
            fill: theme-colors('primary', 'dark');
          }
        }
      }
    }
  }
  &.login-btn {
    font-weight: 600;
  }
}
