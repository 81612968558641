@import '../../../../styles/controls';
.saveResult {
  flex: 1 1 auto;
  @extend %flex-column;
  h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: theme-colors('grays', 'gray-9');
  }
  &__input {
    @extend %flex-column;
    label {
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
