@import '../../styles/controls';
.LoadingContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.Message {
  font-size: 18px;
}
.LoaderInnerContainer {
  margin-bottom: 20px;
  div {
    div {
      width: 100px !important;
      height: 100px !important;
    }
  }
}
