@import "../../styles/controls";
.container,
.containerFluid {
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  @extend %flex-column;
  @include transition(all 0.8s ease-in-out);
}
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 1rem;
  @include media("<phone") {
    max-width: 100%;
  }
  @include media("<576px") {
    max-width: 100%;
  }
  @include media(">=576px") {
    max-width: 540px;
  }
  @include media(">=tablet") {
    max-width: 720px;
  }
  @include media(">=992px") {
    max-width: 960px;
  }
  @include media(">=1200px") {
    max-width: 1240px;
  }
  @include media(">=1400px") {
    max-width: 1320px;
  }
  @include media(">1400px") {
    max-width: 1500px;
  }
}
