@import '../../styles/controls';
.ant-select {
  position: relative;
  @extend %flexbox;
  .ant-select-arrow {
    cursor: pointer;
    top: 50%;
    padding: 0;
    right: 10px;
    margin: 0;
    @extend %flexbox;
    @extend %all-center;
    @include transform(translate(0, -50%));
    .anticon {
      color: theme-colors('grays', 'gray-6');
      width: 21px;
      margin-left: 0px;
      font-size: 14px;
    }
  }

  &.ant-select-single,
  &.ant-select-multiple {
    @extend %isIn;
    &:not(.ant-select-customize-input) {
      margin: 0;
      .ant-select-selector {
        flex: 1 1 auto;
        font-size: 0.875rem;
        max-width: 100%;
        height: auto;
        padding: 0 1.5rem 0 0.5rem;
        min-height: $height-md;
        border: $border;
        color: theme-colors('grays', 'gray-6');
        @extend %radius-md;
        @extend %isIn;
        @extend %item-center;
        .ant-select-selection-placeholder,
        .ant-select-selection-search {
          margin: 0;
          .ant-select-selection-search-input {
            width: 100%;
            height: 100%;
          }
        }
        .ant-select-selection-overflow {
          padding: 0.2rem 0;
          column-gap: 0.25rem;
          row-gap: 0.25rem;
          @extend %flexbox;
          @extend %item-center;
          .ant-select-selection-overflow-item {
            flex: 0 0 auto;
            @extend %flexbox;
            .ant-select-selection-item {
              margin: 0;
              padding: 0 0.25rem;
              @extend %isIn;
            }
            &:hover {
              flex: 0 0 auto;
              .ant-select-selection-item {
                background-color: theme-colors('grays', 'gray-2');
                @extend %isIn;
              }
            }
          }
        }
      }
    }
  }
  &.ant-select-focused {
    background-color: transparent;
    &:not(.ant-select-disabled),
    &:not(.ant-select-customize-input) {
      margin: o;
      .ant-select-selector {
        height: auto;
        border-color: theme-colors('primary', 'base') !important;
        @include box-shadow(0 0 0 transparent !important);
        input {
          cursor: text;
        }
      }
    }
  }
  &.ant-select-show-search {
    background-color: transparent;
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        cursor: pointer;
        input {
          cursor: pointer;
        }
      }
    }
  }
  &.ant-select-single {
    @extend %isIn;
    &:not(.ant-select-customize-input) {
      margin: 0;
    }
  }
  &.ant-select-multiple {
    @extend %isIn;
    &:not(.ant-select-customize-input) {
      margin: 0;
    }
  }
  &.ant-select-lg {
    @extend %isIn;
    &:not(.ant-select-customize-input) {
      flex: 1 1 auto;
      .ant-select-selector {
        min-height: $height-lg;
        font-size: 16px;
        padding: 0.75rem 1;
        @extend %radius-lg;
      }
    }
  }
  &.ant-select-sm {
    @extend %isIn;
    &:not(.ant-select-customize-input) {
      flex: 1 1 auto;
      .ant-select-selector {
        min-height: $height-sm;
        font-size: 14px;
        padding: 0.25rem 0.75rem;
        @extend %radius-sm;
      }
    }
  }
  &.mainsearch-select {
    margin: 0;
    &:not(.ant-select-customize-input) {
      flex: 1 1 auto;
      .ant-select-selector {
        border: 0;
        background-color: theme-colors('primary', 'dark');
        @include border-radius(0.375rem 0 0 0.375rem);
        .ant-select-selection-placeholder,
        .ant-select-selection-item {
          max-width: 120px;
          color: theme-colors('grays', 'gray-0');
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
    .ant-select-arrow {
      margin: 0;
      .anticon {
        color: theme-colors('grays', 'gray-0');
      }
    }
  }
  &.dashboard-building {
    flex: 0 0 auto;
    &:not(.ant-select-customize-input) {
      flex: 1 1 auto;
      .ant-select-selector {
        flex: 1 1 auto;
        .ant-select-selection-item {
          max-width: 140px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }
  }
}
.ant-select-dropdown {
  padding: 0;
  background-color: theme-colors('grays', 'gray-0');
  @include border-radius(10px);
  @include box-shadow(0 4px 4px 1px theme-colors('grays', 'gray-10', 0.1));
  .rc-virtual-list {
    flex: 1 1 auto;
    padding: 1rem;
  }
  // .rc-virtual-list-holder-inner {
  //   row-gap: 0.5rem;
  //   @extend %flex-column;
  //   .ant-select-item {
  //     font-size: 14px;
  //     padding: 0.25rem 1rem;
  //     &.ant-select-item-option-selected {
  //       background-color: theme-colors('primary', 'base', 0.1);
  //       color: theme-colors('grays', 'gray-8');
  //     }
  //     .ant-select-item-option-state {
  //       flex: 0 0 auto;
  //       .anticon-check {
  //         color: theme-colors('primary', 'base');
  //       }
  //     }
  //   }
  // }
}
