@import '../../styles/controls';
.ant-checkbox-wrapper {
  @extend %flexbox;
  @extend %item-center;
  .ant-checkbox {
    top: 0;
    overflow: hidden;
    min-width: 16px;
    min-height: 16px;
    max-width: 16px;
    max-height: 16px;
    @extend %flexbox;
    @extend %all-center;
    @extend %radius-xs;
    @extend %isOut;
    .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-color: theme-colors('grays', 'gray-3');
      @extend %isOut;
    }
    &.ant-checkbox-checked {
      margin: 0;
      .ant-checkbox-inner {
        border-color: theme-colors('primary', 'base');
        @extend %isIn;
        &:after {
          left: 3px;
          border-color: theme-colors('primary', 'base');
        }
      }
    }
    &:hover {
      @extend %isIn;
      .ant-checkbox-inner {
        border-color: theme-colors('secondary', 'base');
        @extend %isIn;
      }
    }
    &:hover {
      color: theme-colors('secondary', 'base');
      @extend %isIn;
      .ant-checkbox-inner {
        color: theme-colors('secondary', 'base');
        @extend %isIn;
        &:after {
          border-color: theme-colors('secondary', 'base');
          @extend %isIn;
        }
      }
    }
  }
}
.ant-checkbox-group {
  row-gap: 1rem;
  @extend %flex-column;
  .ant-checkbox-wrapper {
    margin: 0;
    flex: 1 1 auto;
    span {
      font-size: 14px;
      color: theme-colors('grays', 'gray-9');
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: calc(100vw - 40px);
    }
  }
  &.setting-selection {
    flex: 1 1 auto;
    @extend %flexbox;
    @extend %item-wrap;
    .ant-checkbox-wrapper {
      flex: 0 0 auto;
      margin-right: 0.5rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      span {
        font-size: 18px;
        color: theme-colors('primary', 'base');
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100vw - 40px);
      }
      .ant-checkbox {
        top: 0;
        .ant-checkbox-inner {
          border-color: theme-colors('primary', 'base');
        }
        &.ant-checkbox-checked {
          margin: 0;
          .ant-checkbox-inner {
            @extend %isIn;
            &:after {
              left: 5px;
              border-color: theme-colors('primary', 'base');
            }
          }
        }
      }
    }
  }
}
