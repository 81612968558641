@import "../../styles/controls";
.ant-picker {
  flex: 0 0 auto;
  font-weight: 400;
  max-width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  min-height: $height-md;
  border: $border;
  color: theme-colors("grays", "gray-6");
  @extend %radius-md;
  @extend %isIn;
  &:hover,
  &:focus,
  &:active {
    border-color: theme-colors("secondary", "base");
    @extend %isIn;
    @include box-shadow(0 0 0 transparent);
  }
  .ant-picker-input {
    margin: 0;
    input {
      font-size: 0.813rem;
      font-weight: 400;
      text-transform: capitalize;
    }
  }
  &.ant-picker-large {
    min-height: $height-lg;
    font-size: 16px;
    padding: 0.75rem 1.5rem;
    @extend %radius-lg;
  }
  &.ant-picker-small {
    min-height: $height-sm;
    font-size: 14px;
    padding: 0.25rem 0.75rem;
    @extend %radius-sm;
  }
  &.filter-picker {
    flex: 1 1 auto;
  }
}
