@import '../../styles/controls';
.addtagBlock {
  @extend %flex-column;
  &__nodata {
    flex: 1 1 auto;
    column-gap: 0.5rem;
    @extend %flexbox;
    @extend %all-center;
    span {
      flex: 0 0 auto;
    }
    a {
      flex: 0 0 auto;
      padding: 0.25rem 0.5rem;
      color: theme-colors('grays', 'gray-0');
      background-color: theme-colors('primary', 'base');
      @extend %radius-md;
      @extend %isIn;
      &:hover {
        color: theme-colors('grays', 'gray-8');
        background-color: theme-colors('secondary', 'base');
        @extend %isIn;
      }
    }
  }
  &__list {
    row-gap: 0.75rem;
    @extend %flex-column;
    li {
      @extend %flexbox;
      column-gap: 1rem;
      @extend %item-center;
      @extend %justify-between;
      .tagItems {
        flex: 1 1 auto;
        @extend %flexbox;
        @extend %item-center;
        &__fields {
          flex: 1 1 auto;
        }
      }
      .tagButtonpane {
        flex: 0 0 auto;
        min-width: 150px;
        @extend %flexbox;
        @extend %item-center;
        &__element {
          flex: 0 0 auto;
          &:not(:first-child) {
            margin-left: 1rem;
          }
          button {
            min-width: auto !important;
          }
        }
      }
      .notabPane {
        flex: 1 1 auto;
        height: 30px;
        padding: 0 0.75rem;
        border: $border;
        border: solid 1px theme-colors('functional', 'info');
        background-color: theme-colors('functional', 'info', 0.2);
        color: theme-colors('primary', 'base');
        @extend %flexbox;
        @extend %all-center;
        @extend %radius-sm;
        .tagPlusButton {
          border: $border;
          height: 30px;
          max-width: 80px;
          text-align: center;
          background-color: #fcf8e3;
          padding: 0.1rem 0.75rem;
          margin: 0 0.5rem 0 0.5rem;
          &:focus,
          &:focus-visible {
            outline: 0;
          }
        }
      }
    }
  }
}
.tagWarning {
  padding: 0.5rem 1rem;
  margin-top: 2rem;
  column-gap: 1rem;
  border: solid 1px theme-colors('functional', 'warning');
  background-color: theme-colors('functional', 'warning', 0.2);
  @extend %radius-md;
  @extend %flexbox;
  @extend %item-center;

  span {
    color: theme-colors('grays', 'gray-8');
    &:first-child {
      width: 1rem;
      height: 1rem;
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('grays', 'gray-8');
        }
      }
    }
  }
}
