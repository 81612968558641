@import '../../styles/controls';
.ant-modal-wrap {
  height: 100vh;
  overflow: hidden;
  background-color: theme-colors('grays', 'gray-10', 0.5);
  @extend %isIn;
  // @include animation('fadeIn 0.8s');
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  .ant-modal {
    max-width: 90%;
    overflow: hidden;
    padding-bottom: 0;
    .ant-modal-content {
      flex: 1 1 auto;
      outline: 0;
      overflow: hidden;
      @extend %radius-md;
      @extend %flex-column;
      .ant-modal-close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 99;
        width: 30px;
        height: 30px;
        cursor: pointer;
        @include transform(translate(-50%, 0));
        @extend %flexbox;
        @extend %all-center;
        .ant-modal-close-x {
          flex: 0 0 auto;
          line-height: 20px !important;
          width: auto !important;
          height: auto !important;
          .anticon {
            font-size: 26px;
            color: theme-colors('grays', 'gray-6');
            @extend %isIn;
            &:hover {
              color: theme-colors('grays', 'gray-6');
              @extend %isOut;
            }
          }
        }
      }
      .ant-modal-header,
      .ant-modal-body,
      .ant-modal-footer {
        border: 0;
        padding: 1.25rem;
      }

      .ant-modal-header {
        flex: 0 1 auto;
        @extend %flexbox;
        @extend %item-center;
        .ant-modal-title {
          font-size: 20px;
          font-weight: 500;
          color: theme-colors('grays', 'gray-8');
        }
      }
      .ant-modal-body {
        flex: 1 1 auto;
        @extend %flex-column;
        @extend %justify-center;
        @include media('>=992px') {
          min-width: 500px;
        }
        .model-content-scroll {
          max-height: calc(100vh - 222px);
        }
      }
      .ant-modal-footer {
        flex: 0 1 auto;
        @extend %flexbox;
        @extend %justify-end;
      }
    }

    &.search-model {
      margin: 0 !important;
      width: 100% !important;
      max-width: 100vw !important;
      .ant-modal-content {
        flex: 1 1 auto;
        height: 100vh;
        @include border-radius(0);
        @include box-shadow(0 0 0 transparent);
      }
    }
  }

  &.forgetModel {
    min-width: 600x;
    max-width: 600px;
    .footer-block {
      @extend %flexbox;
      @extend %justify-end;
    }

    .ant-modal-body {
      padding: 15px 25px 25px 25px !important;
    }
  }
}
