@import '../../../../styles/controls';

.preferencesWrapper {
  /* flex: 1 1 auto;
  padding: 1.5rem;
  display: grid;
  column-gap: 1.5rem;
  grid-template-columns: 1fr 250px; 
  @extend %flexbox;*/
  flex: 1 1 auto;
  row-gap: 1rem;
  min-width: 800px;
  margin: 0 auto;
  padding: 1.5rem;
  @extend %flex-column;
  .preferenceSettings {
    flex: 0 1 auto;
    padding: 1rem;
    row-gap: 0.5rem;
    background-color: theme-colors('grays', 'gray-0');
    @extend %flex-column;
    @extend %radius-md;
    &__header {
      @extend %flex-column;
      h5 {
        font-size: 18px;
      }
    }
    &__list {
      flex: 0 1 auto;
      row-gap: 1rem;
      @extend %flex-column;
      li {
        row-gap: 0.25rem;
        flex: 0 0 auto;
        @extend %flex-column;
        &:first-child {
          min-width: 70%;
          flex: 1 1 auto;
        }
        &:last-child {
          // @extend %flexbox;
          align-items: center;
        }
      }
    }
  }
}
