@import '../../styles/controls';

.appLoaderWrapper {
  flex: 1 1 auto;
  height: 100vh;
  @extend %flex-column;
  @extend %all-center;
  .appLoaderInner {
    flex: 0 0 auto;
    @extend %flex-column;
    @extend %all-center;
    &__loadimage {
      flex: 1 1 auto;
      margin-bottom: 1.5rem;
      @extend %flex-column;
      @extend %all-center;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 0.25rem;
    }
    p {
      font-size: 16px;
      // color: rgba($color-black, 0.5);
      color: theme-colors('primary', 'base', 0.8);
    }
  }
}
