@import "../../styles/controls";
.searchInput {
	flex: 1 1 auto;
	position: relative;
	@extend %flex-column;
	.textBox {
		padding: 0.625rem 3rem 0.625rem 0.625rem;
	}
	.lenceIcon {
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 50%;
		width: 20px;
		height: 20px;
		@include transform(translateY(-50%));
		svg {
			width: 100%;
			height: 100%;
			path {
				fill: theme-colors("primary", "base", 0.4);
			}
		}
	}
}
