@import '../../styles/controls';
.headerControls {
  padding: 0.75rem $tab-padding-md;
  column-gap: 1rem;
  row-gap: 1rem;
  background-color: theme-colors('primary', 'dark', 0.06);
  @extend %flexbox;
  @extend %item-center;
  @extend %item-wrap;
  @extend %justify-between;
  .controlsSearch {
    flex: 0 0 auto;
    @extend %flex-column;
    &__list {
      flex: 1 1 auto;
      column-gap: 0.5rem;
      @extend %flexbox;
      @extend %item-center;
      li {
        flex: 0 0 auto;
        a {
          flex: 1 1 auto;
          height: 32px;
          padding: 0 0.5rem;
          color: theme-colors('grays', 'gray-8');
          border: $border;
          @extend %radius-sm;
          @extend %flexbox;
          @extend %all-center;
          &:hover {
            border-color: theme-colors('secondary', 'base');
            color: theme-colors('primary', 'dark');
            @extend %isIn;
          }
        }
        .filtersearchButton {
          padding: 0 0.5rem;
          height: 32px;
          background-color: theme-colors('primary', 'base');
          color: theme-colors('grays', 'gray-0');
          border: $border;
          @extend %radius-sm;
          @extend %flexbox;
          @extend %item-center;
          @extend %isIn;
          &.filterLinkDisable {
            background-color: theme-colors('grays', 'gray-3');
            cursor: not-allowed !important;
          }
          span {
            flex: 0 0 auto;
            &:first-child {
              width: 12px;
              height: 12px;
              margin-right: 0.5rem;
              @extend %flexbox;
              @extend %all-center;
              svg {
                width: 100%;
                height: 100%;
                path {
                  fill: theme-colors('grays', 'gray-0');
                  @extend %isIn;
                }
              }
            }
          }
          &:hover {
            background-color: theme-colors('secondary', 'base');
            border-color: theme-colors('secondary', 'base');
            color: theme-colors('primary', 'dark');
            @extend %isIn;
            span {
              flex: 0 0 auto;
              &:first-child {
                flex: 0 0 auto;
                svg {
                  width: 100%;
                  path {
                    fill: theme-colors('primary', 'dark');
                    @extend %isIn;
                  }
                }
              }
            }
          }
          &[disabled] {
            cursor: not-allowed;
            background-color: theme-colors('grays', 'gray-3');
            span {
              color: theme-colors('grays', 'gray-6');
              svg {
                width: 100%;
                path {
                  fill: theme-colors('grays', 'gray-6');
                }
              }
            }
            &:hover {
              border: theme-colors('grays', 'gray-3') solid 1px;
            }
          }
        }
        .filterToggled {
          margin: 0;
          @extend %isIn;
          span {
            @extend %isIn;
            padding: 0;
            &:first-child {
              @include transform(rotate(180deg));
            }
          }
        }
      }
    }
  }
}
.controlsElement {
  flex: 0 0 auto;
  column-gap: 0.5rem;
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-end;
  .viewInsights {
    flex: 1 1 auto;
    height: 32px;
    font-weight: 500;
    padding: 0 0.5rem;
    color: theme-colors('grays', 'gray-0');
    background-color: theme-colors('primary', 'dark');
    border: theme-colors('primary', 'dark') solid 1px;
    @extend %radius-sm;
    @extend %flexbox;
    @extend %all-center;
    &:hover {
      color: theme-colors('primary', 'dark');
      background-color: theme-colors('grays', 'gray-0');
      border: theme-colors('primary', 'dark') solid 1px;
      @extend %isIn;
    }
  }
  .advancDrawertrigger {
    flex: 0 0 auto;
    @extend %flex-column;
    a {
      flex: 1 1 auto;
      height: 32px;
      padding: 0 0.5rem;
      color: theme-colors('grays', 'gray-7');
      border: $border;
      @extend %radius-sm;
      @extend %flexbox;
      @extend %all-center;
      &:hover {
        border-color: theme-colors('secondary', 'base');
        color: theme-colors('primary', 'dark');
        @extend %isIn;
      }
    }
  }
  &__list {
    flex: 0 0 auto;
    column-gap: 0.5rem;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-end;
    li {
      flex: 0 0 auto;
      cursor: pointer;
      @extend %flexbox;
      @extend %all-center;
      .controlsItem {
        flex: 1 1 auto;
        cursor: pointer;
        min-width: 32px;
        height: 32px;
        color: theme-colors('grays', 'gray-7');
        border: solid 1px theme-colors('primary', 'dark');
        @extend %radius-sm;
        @extend %flex-column;
        @extend %all-center;
        @extend %isOut;
        span {
          flex: 0 0 auto;
          min-width: 32px;
          max-width: 32px;
          @extend %flexbox;
          @extend %all-center;
          @extend %isOut;
          svg {
            width: 70%;
            height: 70%;
            path {
              fill: theme-colors('grays', 'gray-7');
              @extend %isOut;
            }
          }
        }
        a {
          flex: 1 1 auto;
          color: theme-colors('grays', 'gray-7');
          padding: 0 1rem;
          line-height: 31px;
        }
        &:hover {
          background-color: theme-colors('secondary', 'base');
          border-color: theme-colors('secondary', 'base');
          color: theme-colors('primary', 'dark');
          @extend %isIn;
          span {
            @extend %isOut;
            svg {
              flex: 0 0 auto;
              path {
                fill: theme-colors('primary', 'dark');
                @extend %isIn;
              }
            }
          }
        }
      }
    }
  }
}
