@import '../../styles/controls';
.logoElement {
  flex: 0 0 auto;
  @extend %flex-column;
  @extend %all-center;
  span {
    flex: 1 1 auto;
    svg {
      width: 100%;
      .logoSpote {
        fill: #57fb00 !important;
      }
    }
  }
}
