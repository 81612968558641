@import "../../styles/controls";
.emptyState {
  flex: 1 1 auto;
  row-gap: 1rem;
  min-height: 300px;
  @extend %flex-column;
  @extend %all-center;
  &__content {
    @extend %flex-column;
    @extend %all-center;
    .emptyImage {
      flex: 1 1 auto;
      max-width: 200px;
      @extend %flex-column;
      @extend %all-center;
      span {
        flex: 1 1 auto;
      }
    }
    h3 {
      flex: 0 1 auto;
      margin-bottom: 0.5rem;
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: theme-colors('grays', 'gray-8',);
    }
    .stateContent {
      flex: 1 1 auto;
      p {
        font-size: 16px;
        text-align: center;
        color: theme-colors('grays', 'gray-6',);
      }
    }
  }
  &__external {
    flex: 0 1 auto;
  }
  &__button {
    @extend %flexbox;
    @extend %all-center;
  }
}
