@import '../constants/color';
$font-web: 'Red Hat Display', sans-serif;
$medium: 600;
$regular: 400;
@mixin generatorText($size, $weight) {
  font-size: #{$size}rem;
  font-weight: $weight;
}
$font-size: (40px, 36px, 30px, 22px, 16px, 14px);
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: theme-colors('primary', 'dark');
}
@for $i from 1 through 6 {
  h#{$i} {
    font-size: nth($font-size, $i);
  }
}
p {
  margin: 0;
  padding: 0;
  line-height: 22px;
  color: theme-colors('grays', 'gray-8');
}
em {
  font-weight: 500;
  background-color: theme-colors('functional', 'highlight');
}
a {
  text-decoration: none;
  color: theme-colors('grays', 'gray-8');
  [disabled] {
    color: theme-colors('primary', 'light', 0.3);
    cursor: not-allowed;
    pointer-events: all;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    color: theme-colors('primary', 'dark');
  }
}
