@import 'styles/controls';
.insightWrapper {
  flex: 1 1 auto;
  padding: $tab-padding-md;
  column-gap: 1rem;
  @extend %flexbox;
  .insightContent {
    flex: 1 1 auto;
    @extend %flex-column;
    .insightBlock {
      flex: 1 1 auto;
      row-gap: 1.5rem;
      @extend %flex-column;
      .advancesearchInsights {
        column-gap: 1rem;
        position: relative;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-between;
        &__item {
          flex: 1 1 auto;
        }
        &__button {
          position: absolute;
          right: 20px;
          top: 10px;
          min-width: 26px;
          min-height: 26px;
          height: 26px;
          border: solid 1px theme-colors('functional', 'info');
          @extend %flexbox;
          @extend %all-center;
          @extend %radius-round;
          span {
            width: 14px;
            height: 14px;
            @extend %flexbox;
            @extend %all-center;
            svg {
              width: 100%;
              height: 100%;
              path {
                fill: theme-colors('functional', 'info');
              }
            }
          }

          &:hover {
            background-color: theme-colors('functional', 'info');
            path {
              fill: theme-colors('grays', 'gray-0');
            }
          }
        }
      }
      &__tag {
        flex: 0 1 auto;
      }
      &__chart {
        row-gap: 1rem;
        flex: 1 1 auto;
        min-height: 1280px;
        @extend %flex-column;
        .chartLabels {
          flex: 0 0 auto;
          @extend %flexbox;
          @extend %item-center;
          h3 {
            font-size: 18px;
            font-weight: 600;
            min-width: 210px;
            width: auto;
            font-style: italic;
            color: theme-colors('primary', 'dark');
          }
        }
      }
    }
    .insightInformation {
      flex: 0 1 auto;
      padding-left: 1rem;
      @extend %flex-column;
      .informationResource {
        margin-top: 1rem;
        padding: 1.438rem;
        background-color: theme-colors('grays', 'gray-0');
        @extend %flex-column;
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-left: 0.25rem;
          margin-bottom: 0.5rem;
        }
        &__list {
          @extend %flexbox;
          @extend %item-center;
          @extend %item-wrap;
          li {
            flex: 0 0 auto;
            padding: 0.5rem 1rem;
            margin: 0.25rem;
            //cursor: pointer;
            color: theme-colors('grays', 'gray-0');
            background-color: theme-colors('primary', 'base', 0.8);
            @extend %radius-lg;
          }
        }
      }
      .insightControl {
        flex: 0 1 auto;
        padding: 1rem 0 0 0;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-end;
      }
    }
  }
}
.insightSidebar {
  flex: 1 0 auto;
  min-width: 280px;
  max-width: 280px;
  @extend %flex-column;
}


.insightDrawer {
  position: fixed;
  height: 100vh;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  max-width: 800px;
  min-width: 800px;
  overflow: hidden;
  z-index: 999;
  background-color: theme-colors('grays', 'gray-0');
  @include box-shadow(-2px 0 8px 1px theme-colors('grays', 'gray-10', 0.1));
  @extend %isIn;
  @extend %flex-column;
  @include transform(translateX(100%));

  &__header {
    padding: 1.5rem;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    h3 {
      font-size: 18px;
      font-weight: bolder;
    }
    .drawerClose {
      cursor: pointer;
      width: 20px;
      height: 20px;
      border: solid 1px theme-colors('grays', 'gray-5');
      @extend %flexbox;
      @extend %all-center;
      @extend %radius-round;
      @extend %isOut;
      svg {
        width: 50%;
        height: 50%;
        path {
          fill: theme-colors('grays', 'gray-5');
          @extend %isOut;
        }
      }
      &:hover {
        background-color: theme-colors('secondary', 'base');
        border: solid 1px theme-colors('secondary', 'base');
        @extend %isIn;
        path {
          fill: theme-colors('grays', 'gray-0');
          @extend %isIn;
        }
      }
    }
  }
  &.drawerinsightActive {
    opacity: 1;
    @extend %isIn;
    @include transform(translateX(0));
  }
  .drawerContent {
    row-gap: 1.5rem;
    @extend %flex-column;
    &__header {
      padding: 0 1.5rem;
      padding-bottom: 1rem;
      border-bottom: $border;
      @extend %flexbox;
      @extend %item-center;
      @extend %justify-between;
      h5 {
        flex: 0 0 auto;
      }
    }
    &__body {
      flex: 1 1 auto;
      @extend %flex-column;
      .drawercontentBlock {
        padding: 0 1.5rem;
        flex: 1 1 auto;
        .drawerbodyList {
          row-gap: 1rem;
          @extend %flex-column;
          li {
            flex: 1 1 auto;
          }
        }
      }
    }
  }
}
