@import '../../styles/controls';
.ant-table-wrapper {
  flex: 1 1 auto;
  @extend %flex-column;
  table {
    display: table;
    .ant-table-thead {
      margin: 0;
      tr {
        @extend %isIn;
        .ant-table-cell {
          padding: 0.75rem 1.5rem;
          vertical-align: middle;
          font-weight: 600;
          color: theme-colors('grays', 'gray-9');
          border-bottom: $border;
          background-color: theme-colors('grays', 'gray-0');
          .ant-table-column-sorters {
            @extend %flexbox;
            @extend %item-center;
            .ant-table-column-title {
              flex: 1 1 auto;
              order: 2;
              font-weight: 600;
              color: theme-colors('grays', 'gray-9');
            }
            .ant-table-column-sorter {
              flex: 0 0 auto;
              order: 1;
              margin-left: 0;
              margin-right: 0.5rem;
              .anticon {
                font-size: 14px;
                &.active {
                  color: theme-colors('primary', 'dark');
                }
              }
            }
          }
        }
      }
    }
    .ant-table-tbody {
      margin: 0;
      .ant-table-row {
        @extend %isIn;
        .ant-table-cell {
          padding: 0.75em 1.5rem;
          vertical-align: middle;
        }
      }
    }
  }
}
