@import '../../styles/controls';
.pageWrapper {
  flex: 1 1 auto;
  @extend %flexbox;
  @extend %item-start;
  @extend %isOut;
  @include animation('fadeIn 0.8s');
  @include keyframes(fadeIn) {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
