$font-size: (28px, 26px, 22px, 18px, 16px, 14px);
$height-sm: 32px;
$height-md: 38px;
$height-lg: 44px;
$search-input: 46px;
$login-input: 42px;
$login-submit: 42px;
/*- =========== Border ==========-*/
$border: theme-colors('grays', 'gray-4') solid 1px;
$line: theme-colors('grays', 'gray-2', 0.5) solid 1px;
$border-hover: theme-colors('secondary', 'base');

/*- =========== tab-height ==========-*/
$tab-height-sm: 40px;
$tab-height-md: 60px;
$tab-height-lg: 80px;
/*- =========== tab-height ==========-*/
$tab-padding-sm: 1rem;
$tab-padding-md: 1.5rem;
$tab-padding-lg: 2rem;
/*- =========== content body padding ==========-*/
$content-padding-sm: 1rem;
$content-padding-md: 1.5rem;
$content-padding-lg: 2rem;
