.react-grid-layout {
  margin: -10px;
  .react-grid-item {
    margin: 0;
    @media print {
      width: 100% !important;
    }
  }
}
.apexcharts-canvas {
  width: 100% !important;
  height: 100% !important;
  @extend %flex-column;
  @extend %item-center;
}
.sc-kMrHXl {
  height: calc(100vh - 280px) !important;
}

.ieiYEj {
  height: calc(100vh - 280px) !important;
  padding: 0 !important;
}
.jHsBFh {
  padding: 0 !important;
  overflow: visible !important;
}
