@import '../../styles/controls';

.loader-block {
  flex: 1 1 auto;
  width: 100%;
  min-height: 150px;
  @extend %flexbox;
  @extend %all-center;
}
.circularLoader {
  flex: 0 auto;
  display: block;
  position: relative;
  overflow: hidden;
  &.lg {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
  }
  &.md {
    width: 34px;
    height: 34px;
    min-width: 34px;
    min-height: 34px;
  }
  &.sm {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
  }
  &.xs {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
  &.dark {
    padding: 0;
    .circularLoader__item {
      padding: 0;
      &:before {
        background-color: #484848;
      }
    }
  }
  &.white {
    padding: 0;
    .circularLoader__item {
      padding: 0;
      &:before {
        background-color: theme-colors('grays', 'gray-0');
      }
    }
  }
  &.blue {
    padding: 0;
    .circularLoader__item {
      padding: 0;
      &:before {
        background-color: #3589c4;
      }
    }
  }
  &__item {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
    &:before {
      content: '';
      display: block;
      margin: 0 auto;
      overflow: hidden;
      width: 15%;
      height: 15%;
      border-radius: 100%;
      -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
      @include border-radius(50%);
    }
    &:nth-child(2) {
      -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
      transform: rotate(30deg);
      &:before {
        -webkit-animation-delay: -1.1s;
        animation-delay: -1.1s;
      }
    }
    &:nth-child(3) {
      -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
      transform: rotate(60deg);
      &:before {
        -webkit-animation-delay: -1s;
        animation-delay: -1s;
      }
    }
    &:nth-child(4) {
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      &:before {
        -webkit-animation-delay: -0.9s;
        animation-delay: -0.9s;
      }
    }
    &:nth-child(5) {
      -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
      transform: rotate(120deg);
      &:before {
        -webkit-animation-delay: -0.8s;
        animation-delay: -0.8s;
      }
    }
    &:nth-child(6) {
      -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
      transform: rotate(150deg);
      &:before {
        -webkit-animation-delay: -0.7s;
        animation-delay: -0.7s;
      }
    }
    &:nth-child(7) {
      -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      transform: rotate(180deg);
      &:before {
        -webkit-animation-delay: -0.6s;
        animation-delay: -0.6s;
      }
    }
    &:nth-child(8) {
      -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
      transform: rotate(210deg);
      &:before {
        -webkit-animation-delay: -0.5s;
        animation-delay: -0.5s;
      }
    }
    &:nth-child(9) {
      -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
      transform: rotate(240deg);
      &:before {
        -webkit-animation-delay: -0.4s;
        animation-delay: -0.4s;
      }
    }
    &:nth-child(10) {
      -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
      transform: rotate(270deg);
      &:before {
        -webkit-animation-delay: -0.3s;
        animation-delay: -0.3s;
      }
    }
    &:nth-child(11) {
      -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
      transform: rotate(300deg);
      &:before {
        -webkit-animation-delay: -0.2s;
        animation-delay: -0.2s;
      }
    }
    &:nth-child(12) {
      -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
      transform: rotate(330deg);
      &:before {
        -webkit-animation-delay: -0.1s;
        animation-delay: -0.1s;
      }
    }
  }
}

@-webkit-keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

@keyframes sk-circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}

.linearLoader {
  overflow: hidden;
  width: 10%;
  min-width: 80px;
  background-color: rgba(var(--color-text-gray), 0.3);
  display: inline-block;
  height: 3px;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    width: 0vw;
    height: 3px;
    left: 0;
    animation: linear-loader-bar-movement 1.5s infinite linear;
  }
  &.blue {
    &::before {
      background-color: #3589c4;
    }
  }
  &.white {
    &::before {
      background-color: theme-colors('grays', 'gray-0');
    }
  }
  &.dark {
    &::before {
      background-color: #484848;
    }
  }
}

@keyframes linear-loader-bar-movement {
  25% {
    transform: translateX(25%);
  }
  50% {
    width: 50%;
  }
  70% {
    width: 80%;
    transform: translateX(80%);
  }
  to {
    width: 100%;
    transform: translateX(100%);
  }
}
