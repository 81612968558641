@import '../../styles/controls';
.advancesearchBlock {
  flex: 1 1 auto;
  position: relative;
  padding: 0;
  background-color: theme-colors('grays', 'gray-0');
  @extend %flex-column;
  &__header {
    padding: 1rem 2.5rem 1rem 1rem;
    position: relative;
    background-color: theme-colors('grays', 'gray-0');
    border-bottom: solid 1px theme-colors('primary', 'base', 0.2);
    @extend %flex-column;
    @extend %justify-center;

    h3 {
      font-size: 18px;
      color: theme-colors('grays', 'gray-8');
      font-weight: bolder;
    }
    p {
      color: theme-colors('grays', 'gray-7');
    }
  }
  .advancesearchInner {
    flex: 1 1 auto;
    background-color: theme-colors('grays', 'gray-1');
    @extend %flex-column;
    &__body {
      flex: 1 1 auto;
      padding: 1rem 0;
      @extend %flex-column;
    }
    &__footer {
      background-color: theme-colors('grays', 'gray-0');
      border-top: $line;
      flex: 0 1 auto;
      padding: 1.5rem;
      .advanceList {
        column-gap: 0.5rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-end;
        li {
          flex: 0 0 auto;
        }
      }
    }
  }
}

.searchClose {
  cursor: pointer;
  position: absolute;
  right: 1rem;
  top: 20px;
  width: 20px;
  height: 20px;
  border: solid 1px theme-colors('grays', 'gray-5');
  @extend %flexbox;
  @extend %all-center;
  @extend %radius-round;
  @extend %isOut;
  svg {
    width: 50%;
    height: 50%;
    path {
      fill: theme-colors('grays', 'gray-5');
      @extend %isOut;
    }
  }
  &:hover {
    background-color: theme-colors('secondary', 'base');
    border: solid 1px theme-colors('secondary', 'base');
    @extend %isIn;
    path {
      fill: theme-colors('grays', 'gray-0');
      @extend %isIn;
    }
  }
}