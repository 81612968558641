@import "../../styles/controls";
.ant-collapse {
  border: 0;
  outline: 0;
  margin: 0;
  background-color: theme-colors("grays", "gray-0");
  @extend %flex-column;
  .ant-collapse-item {
    border: 0;
    flex: 0 1 auto;
    margin: 1px 0;
    background-color: transparent;
    @extend %flex-column;
    .ant-collapse-header {
      padding: 0.5rem;
      font-weight: 700;
      column-gap: 0.5rem;
      color: theme-colors("grays", "gray-9");
      @extend %flexbox;
      @extend %item-center;
      .ant-collapse-arrow {
        flex: 0 0 auto;
        margin-right: 0.125rem;
        min-width: 18px;
        min-height: 18px;
        max-width: 18px;
        max-height: 18px;
        @extend %flexbox;
        @extend %all-center;
        svg {
          width: 50%;
          height: 50%;
          fill: theme-colors("grays", "gray-9");
        }
      }
      .ant-collapse-header-text {
        font-size: 14px;
        font-weight: 600;
        color: theme-colors("grays", "gray-8");
      }
    }
    .ant-collapse-content {
      border: $line;
      .ant-collapse-content-box {
        padding: 0.5rem;
      }
    }
  }
  &.filter-colapse {
    @extend %isOut;
    border-bottom: $line;
    &:not(:last-child) {
      @extend %isOut;
      border-bottom: $line;
    }
    .ant-collapse-item {
      margin: 0;
      .ant-collapse-content {
        border: 0;
        .ant-collapse-content-box {
          padding: 1rem;
          background-color: theme-colors("grays", "gray-1", 0.2);
        }
      }
      &.ant-collapse-item-active {
        margin: 0;
        .ant-collapse-header {
          border-bottom: $line;
          @extend %isIn;
          .ant-collapse-header-text {
            color: theme-colors("primary", "base");
          }
        }
      }
    }
  }
}
