@import '../../styles/controls';
.mainSidebar {
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 10;
  flex: 1 1 auto;
  overflow: hidden;
  min-width: 60px;
  max-width: 60px;
  background-color: theme-colors('grays', 'gray-1');
  border-right: $line;
  @extend %flex-column;
  @extend %isIn;
  .headerBrand {
    flex: 0 0 auto;
    overflow: hidden;
    position: relative;
    padding: 0.5rem 1rem;
    height: $tab-height-md;
    @extend %flex-column;
    &__mainlogo,
    &__emblem {
      position: absolute;
      flex: 1 1 auto;
      @extend %flex-column;
      @extend %isIn;
    }
    &__mainlogo {
      max-width: 115px;
      left: 1rem;
      top: 50%;
      @include transform(translateY(-50%) scale(0));
      @extend %justify-center;
    }
    &__emblem {
      top: 50%;
      left: 50%;
      min-width: 26px;
      min-height: 26px;
      max-width: 26px;
      max-height: 26px;
      @extend %all-center;
      @include transform(translate(-50%, -50%) scale(1));
      span {
        flex: 1 1 auto;
        max-width: 26px;
        max-height: 26px;
        min-width: 26px;
        min-height: 26px;
        max-width: 26px;
        max-height: 26px;
        @extend %flex-column;
        @extend %all-center;
      }
    }
  }
  .sidebarMenu {
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      @extend %flex-column;
      a {
        flex: 1 1 auto;
        column-gap: 1rem;
        font-size: 0px;
        color: theme-colors('grays', 'gray-8');
        padding: 1rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %isIn;
        span {
          flex: 0 0 auto;
          &:first-child {
            width: 26px;
            height: 26px;
            @extend %flexbox;
            @extend %all-center;
            svg {
              width: 75%;
              height: 75%;
              @extend %isIn;
              path {
                fill: theme-colors('grays', 'gray-6');
              }
            }
          }
        }
        &:hover {
          background-color: theme-colors('primary', 'light', 0.5);
          color: theme-colors('primary', 'dark');
          @extend %isIn;
          span {
            outline: 0;
            svg {
              @extend %isIn;
              path {
                fill: theme-colors('primary', 'dark');
                @extend %isIn;
              }
            }
          }
        }
      }
      &.menuActive {
        flex: 1 1 auto;
        a {
          background-color: theme-colors('primary', 'base');
          color: theme-colors('grays', 'gray-0');
          @extend %isIn;
          span {
            outline: 0;
            svg {
              width: 100%;
              path {
                fill: theme-colors('grays', 'gray-0');
                @extend %isIn;
              }
            }
          }
        }
      }
    }
  }
  .logoutButton {
    flex: 0 0 auto;
    margin-top: auto;
    @extend %flex-column;
    a {
      flex: 1 1 auto;
      padding: 1rem;
      @extend %flexbox;
      @extend %all-center;
      span {
        width: 26px;
        height: 26px;
        @extend %flexbox;
        @extend %all-center;
      }
      svg {
        width: 75%;
        height: 75%;
        @extend %isIn;
        path {
          fill: theme-colors('grays', 'gray-6');
        }
      }
      &:hover {
        background-color: theme-colors('primary', 'light', 0.5);
        color: theme-colors('primary', 'dark');
        @extend %isIn;
        span {
          outline: 0;
          svg {
            @extend %isIn;
            path {
              fill: theme-colors('primary', 'dark');
              @extend %isIn;
            }
          }
        }
      }
    }
  }
}
