@import "../../styles/controls";
.formFeild {
  flex: 0 0 auto;
  position: relative;
  @extend %flex-column;
  .formElement {
    flex: 1 1 auto;
    .feildlabel {
      flex: 1 1 auto;
      margin-bottom: 0.12rem;
      @extend %flexbox;
      @extend %item-center;
      .labelBlock {
        font-size: 14px;
        font-weight: 400;
        color: theme-colors("grays", "gray-8");
        &:first-letter {
          text-transform: capitalize;
        }
      }
      .starIcon {
        width: 0.469rem;
        height: 0.469rem;
        font-size: 1.2rem;
        margin-left: 3px;
        @extend %flexbox;
        @extend %all-center;
        svg {
          width: 100%;
          height: 100%;
          path {
            fill: theme-colors("functional", "error");
          }
        }
      }
    }
  }
  .formHint {
    flex: 1 1 auto;
    margin-top: 2px;
    font-size: 12px;
    &.textRight {
      text-align: right;
    }
  }
  .error {
    color: theme-colors("functional", "error");
    font-style: italic;
    font-size: 12px;
    padding-top: 3px;
    line-height: 14px;
    @extend %flexbox;
    @extend %item-center;
    &:before {
      content: "*";
      width: 10px;
      height: 10px;
      left: 0;
      top: 7px;
      font-size: 16px;
    }
  }
}
