@import 'styles/controls';

.cardSelecter {
  flex: 1 1 auto;
  @extend %flexbox;
  @extend %item-center;
  li {
    flex: 0 0 auto;
    @extend %flex-column;
    &:not(:last-child) {
      margin-right: 0.5rem;
      @include media('>=992px') {
        margin-right: 1rem;
      }
    }
    .listselectButton {
      flex: 1 1 auto;
      cursor: pointer;
      padding: 0;
      min-width: 32px;
      min-height: 32px;
      max-width: 32px;
      max-height: 32px;
      color: theme-colors('grays', 'gray-7');
      border: $border;
      @extend %radius-md;
      @extend %flexbox;
      @extend %all-center;
      @extend %isOut;
      span {
        width: 16px;
        height: 16px;
        @extend %flexbox;
        @extend %all-center;
        &:empty {
          display: none;
        }
      }
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('grays', 'gray-6');
          @extend %isOut;
        }
      }

      &.selectedCard {
        background-color: theme-colors('primary', 'dark');
        @extend %isIn;
        svg {
          width: 100%;
          path {
            fill: theme-colors('grays', 'gray-0');
            @extend %isIn;
          }
        }
      }
      &:hover {
        background-color: theme-colors('secondary', 'base');
        border: theme-colors('secondary', 'base') solid 1px;
        @extend %isIn;
        svg {
          width: 100%;
          path {
            fill: theme-colors('primary', 'dark');
            @extend %isIn;
          }
        }
      }
      &:disabled {
        cursor: not-allowed;
        border-color: theme-colors('grays', 'gray-6');
        background-color: theme-colors('grays', 'gray-6');
        svg {
          width: 100%;
          path {
            fill: theme-colors('grays', 'gray-8');
            @extend %isIn;
          }
        }
      }
    }
  }
}
