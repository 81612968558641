@import "../../styles/controls";
.ant-input {
  margin: 0;
  padding: 0.625rem;
  font-size: 0.875rem;
  height: $height-md;
  border: $border;
  color: theme-colors("grays", "gray-5");
  @extend %radius-sm;
  @extend %radius-md;
  @include placeholder {
    color: theme-colors("grays", "gray-5");
  }
  &:focus,
  &:hover {
    outline: 0;
    color: theme-colors("primary", "dark");
    border-color: theme-colors("secondary", "base");
    @include box-shadow(0 0 0 transparent);
    @extend %isIn;
  }
  &:focus {
    @extend %isIn;
    @include placeholder {
      @extend %isIn;
    }
  }

  &.ant-input-lg {
    height: $height-lg;
    font-size: 16px;
    padding: 0.625;
    @extend %radius-lg;
  }
  &.ant-input-sm {
    height: $height-sm;
    font-size: 14px;
    padding: 0.5rem;
    @extend %radius-xs;
  }
}
.login-field {
  margin: 0;
  .ant-input {
    border: 0 !important;
  }
}
.ant-input[type="number"] {
  max-width: 80px;
}
