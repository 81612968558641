@import '../../styles/controls';
.savedDashboard {
  flex: 1 1 auto;
  row-gap: 1rem;
  @extend %flex-column;
  .dashboardData {
    flex: 1 1 auto;
    @extend %flex-column;
    &__table {
      flex: 1 1 auto;
    }
    &__pagination {
      flex: 0 1 auto;
    }
  }
}
.dashboardPagination {
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
}

.DashboardContainer {
  flex: 1 1 auto;
  max-width: 100%;
  row-gap: 1rem;
  @extend %flex-column;
  .insightButtonBlock {
    @extend %flexbox;
    @extend %item-center;
    li {
      margin-left: 0.5rem;
    }
  }
}
