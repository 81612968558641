@import '../../styles/controls';
.profileBlock {
  column-gap: 0.5rem;
  overflow: hidden;
  @extend %flexbox;
  @extend %item-center;
  &__image {
    background-color: theme-colors('grays', 'gray-2');
    min-width: 36px;
    min-height: 36px;
    max-width: 36px;
    max-height: 36px;
    @extend %radius-round;
    @extend %flexbox;
    @extend %all-center;
    span {
      min-width: 34px;
      min-height: 34px;
      max-width: 34px;
      max-height: 34px;
      overflow: hidden;
      @extend %radius-round;
      @extend %flexbox;
      @extend %all-center;
    }
  }
  &__name {
    flex: 0 0 auto;
    @extend %flex-column;
    @extend %justify-center;
    .loaderDiv{
      width: 90px;
    }
    h4 {
      color: theme-colors('grays', 'gray-6');
      line-height: 18px;
      font-size: 14px;
      @extend %isIn;
    }
    p {
      font-size: 12px;
      line-height: 16px;;
      color: theme-colors('grays', 'gray-6');
      @extend %isIn;
    }
  }
}

