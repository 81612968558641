@import '../../styles/controls';
.loginWrapper {
  min-height: 100vh;
  display: grid;
  gap: 0 0;
  background-image: url(../../../public/img/banner-background.jpg);
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  @extend %login-theam;
  @include media('>desktop') {
    grid-template-columns: repeat(2, 1fr);
  }
  &__left {
    background-color: transparent;
    @extend %flex-column;
    @extend %item-end;
  }
  &__right {
    @extend %flex-column;
  }
}
