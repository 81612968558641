@import "../../../styles/controls";
.viewUserblock {
    @extend %flex-column;
    &__header {
        margin-bottom: 1rem;
        @extend %flexbox;
        @extend %item-center;
        @extend %justify-between;
        h3 {
            font-size: 20px;
            font-weight: 500;
            color: theme-colors("primary", "dark");
        }
        .userEdit {
            width: 22px;
            height: 22px;
            cursor: pointer;
            svg {
                width: 100%;
                height: 100%;
                path {
                    fill: theme-colors("grays", "gray-5");
                    @extend %isIn;
                }
            }
        }
    }
    &__list {
        @extend %flex-column;
        li {
            padding: 0.75rem 0;
            @extend %flexbox;
            &:not(:last-child) {
                border-bottom: $line;
            }
            span {
                flex: 0 0 auto;
                &:not(:last-child) {
                    margin-right: 1rem;
                }
                &:first-child {
                    min-width: 140px;
                }
                &:last-child {
                    font-weight: 600;
                    max-width: calc(100% - 140px);
                    word-wrap: break-word;
                }
            }
        }
    }
}
.userEditlist {
    @extend %flex-column;
    li {
        @extend %flex-column;
        &:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
}
