@import '../../styles/controls';
.dropdownBlock {
  position: relative;
  cursor: pointer;
  @extend %flex-column;
  .dropdownMenu {
    position: absolute;
    right: 0;
    min-width: 280px;
    overflow: hidden;
    max-height: 0;
    top: 100%;
    z-index: 9;
    background-color: theme-colors('grays', 'gray-0');
    @include transform(scale(0) translateY(0));
    @include transform-origin(right 0);
    @include border-radius(0 0 1rem 1rem);
    @include box-shadow(0 8px 8px theme-colors('primary', 'base', 0.1));
    @extend %isOut;
    &:before {
      top: -30px;
      right: 10px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-bottom-color: theme-colors('grays', 'gray-0');
      border-width: 16px;
    }
  }
  &.collapse {
    margin: 0;
    @include transition(all 0.3s ease-in-out);
    .dropdownMenu {
      opacity: 1;
      overflow: visible;
      max-height: 500px;
      @include transform(scale(1) translateY(12px));
      @extend %isIn;
    }
  }
}
