@import '../../../styles/controls';
.dashboardWrapper {
  flex: 1 1 auto;
  @extend %flex-column;
  .dashboardForm {
    margin-top: 8px;
    @extend %flex-column;
    @extend %all-center;
    &__inner {
      min-width: 400px;
      @extend %flex-column;
      .dashboardSearch {
        flex: 0 1 auto;
        column-gap: 1rem;
        @extend %flexbox;
        @extend %item-center;
        &__select {
          flex: 1 1 auto;
          position: relative;
          max-width: 450px;
          min-width: 450px;
          .dashboardInput {
            flex: 0 0 auto;
          }
        }
        &__button {
          flex: 0 0 auto;
        }
        .ant-select-clear {
          opacity: 1 !important;
        }
      }
      .dashboardChecklist {
        flex: 1 1 auto;
        position: absolute;
        top: 100%;
        padding: 1rem 0;
        max-width: 280px;
        min-width: 280px;
        background-color: theme-colors('grays', 'gray-0');
        @include box-shadow(0 0 8px 4px theme-colors('grays', 'gray-10', 0.06));
        .checklistInner {
          padding: 1rem;
          @extend %flex-column;
        }
        .checklistButton {
          margin-top: 2rem;
          @extend %flex-column;
          @extend %all-center;
        }
      }
    }
  }
}
.infopagElement {
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
}
.dashboadrinfoTable {
  row-gap: 1rem;
  @extend %flex-column;
  &__header {
    flex: 0 1 auto;
    @extend %flexbox;
    @extend %item-center;
    @extend %justify-between;
    .dashboadrinfoControls {
      @extend %flexbox;
      @extend %item-center;
      li {
        @extend %flexbox;
        @extend %all-center;
        a {
          border: $border;
          padding: 0.25rem 0.5rem;
          column-gap: 0.25rem;
          @extend %flexbox;
          @extend %item-center;
          @extend %radius-md;
          @extend %isOut;
          span {
            flex: 0 0 auto;
            &:first-child {
              width: 14px;
              height: 14px;
              @extend %flexbox;
              @extend %item-center;
              svg {
                width: 100%;
                height: 100%;
                path {
                  fill: theme-colors('grays', 'gray-8');
                }
              }
            }
          }
          &:hover,
          &:active {
            color: theme-colors('grays', 'gray-0');
            background-color: theme-colors('primary', 'base');
            @extend %isIn;
            span {
              flex: 0 0 auto;
              &:first-child {
                width: 14px;
                svg {
                  width: 100%;
                  height: 100%;
                  path {
                    fill: theme-colors('grays', 'gray-0');
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__content {
    flex: 1 1 auto;
    row-gap: 1rem;
    @extend %flex-column;
  }
}
.importDashboard {
  row-gap: 1rem;
  @extend %flex-column;
  &__header {
    flex: 0 1 auto;
    @extend %flex-column;
    h3 {
      font-size: 24px;
      font-weight: 600;
    }
  }
  &__content {
    flex: 1 1 auto;
    row-gap: 1rem;
    @extend %flex-column;
    .dashboardMessage {
      flex: 0 1 auto;
      padding: 1rem;
      background-color: theme-colors('functional', 'info', 0.4);
      @extend %flex-column;
      p {
        font-size: 13px;
        line-height: 17px;
      }
    }
    .dashboardErrormsg {
      flex: 0 1 auto;
      padding: 0.2rem 1rem;
      background-color: theme-colors('functional', 'error', 0.2);
      @extend %flex-column;
      @extend %isOut;
      p {
        font-size: 13px;
        line-height: 17px;
      }
    }
    .importSearch {
      margin: 0;
    }
    .importChecklist {
      @extend %flex-column;
      .checklistBlock {
        @extend %flex-column;
      }
      .loadMoreLink {
        @extend %flexbox;
        color: theme-colors('secondary', 'base');
        justify-content: center;
      }
    }
  }
}
