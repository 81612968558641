@import '../../../../../../styles/controls';
.controlsBox {
  @extend %flexbox;
  @extend %item-center;
  @extend %justify-between;
  .sortTrigger {
    padding: 0.12rem 0.5rem;
    height: 32px;
    border: $border;
    @extend %flexbox;
    @extend %all-center;
    @extend %radius-sm;
    @extend %isIn;
    span {
      flex: 0 0 auto;
      &:not(:last-child) {
        margin-right: 0.5rem;
      }
      &:first-child {
        color: theme-colors('grays', 'gray-7');
      }
      &:last-child {
        font-weight: 600;
        color: theme-colors('grays', 'gray-7');
      }
    }
    .linkButton {
      flex: 1 1 auto;
      @extend %flexbox;
      @extend %item-center;
      @extend %radius-sm;
      span {
        flex: 0 0 auto;
        &:first-child {
          width: 16px;
          height: 16px;
          margin-right: 0.25rem;
          overflow: hidden;
          @extend %flexbox;
          @extend %all-center;
          svg {
            width: 80%;
            height: 80%;
            path {
              fill: theme-colors('grays', 'gray-6');
            }
          }
        }
      }
      &.activeDown,
      &.activeUp {
        flex: 1 1 auto;
      }
    }
    &:hover {
      border-color: theme-colors('secondary', 'base');
      @extend %isIn;
      span {
        color: theme-colors('primary', 'dark');
        @extend %isIn;
        svg {
          @extend %isIn;
          path {
            fill: theme-colors('primary', 'dark');
            @extend %isIn;
          }
        }
      }
      span {
        @extend %isIn;
        &:first-child {
          svg {
            @extend %isIn;
            path {
              fill: theme-colors('primary', 'dark');
              @extend %isIn;
            }
          }
        }
      }
    }
  }
  .sortDroplist {
    @extend %flex-column;
    li {
      flex: 1 1 auto;
      padding: 0.5rem 1.5rem;
      color: theme-colors('primary', 'dark');
      @extend %isOut;
      &:hover {
        color: theme-colors('secondary', 'base');
        background-color: theme-colors('primary', 'base', 0.1);
        @extend %isIn;
      }
    }
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
}
