@import '../../styles/controls';
.alertBlock {
  @extend %flex-column;
  .alertInner {
    padding: 0.5rem 1rem;
    column-gap: 1rem;
    @extend %radius-xl;
    @extend %flexbox;
    &__icon {
      flex: 0 0 auto;
      width: 30px;
      height: 30px;
      @extend %flexbox;
      @extend %all-center;
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('primary', 'base');
        }
      }
    }
    p {
      font-size: 14px;
      max-width: calc(95% - 1rem);
      padding-right: 1rem;
      .textViewmore {
        flex: 0 0 auto;
        margin-left: 0.5rem;
        font-size: 13px;
        cursor: pointer;
        color: theme-colors('primary', 'base');
        &:hover {
          color: theme-colors('primary', 'dark');
          text-decoration: underline;
        }
      }
    }
    &.alertInfo {
      border: solid 1px theme-colors('functional', 'info');
      background-color: theme-colors('functional', 'info', 0.2);
      p {
        color: theme-colors('functional', 'info');
      }
      svg {
        width: 100%;
        height: 100%;
        path {
          fill: theme-colors('functional', 'info');
        }
      }
    }
  }
}
